import {
    Action,
    ActionTrigger,
    ButtonActionType,
    getButtonComponent,
    IButtonComponent,
} from "@/builder/components/button/ButtonInterface";
import {
    getButtonGroupComponent,
    IButtonGroupComponent,
} from "@/builder/components/buttonGroup/ButtonGroupInterface";
import {
    getFooterComponent,
    IFooterComponent,
} from "@/builder/components/footer/IFooterInterface";
import {
    getIconComponent,
    IIconComponent,
} from "@/builder/components/icon/IconInterface";
import {
    getPaymentComponent,
    IPaymentComponent,
} from "@/builder/components/payment/PaymentInterface";
import {
    getTextInputComponent,
    InputType,
} from "@/builder/components/textInput/TextInputInterface";
import {
    ComponentStyles,
    GiftArrayOption,
    IAnonymousGivingInterface,
    IBuilder,
    IColumnComponent,
    IConfirmationComponent,
    ICoverCostComponent,
    ICustomComponent,
    IDonationBlockComponent,
    IFormComponent,
    IFormStepComponent,
    IFrequencyComponent,
    IHeaderComponent,
    IHtmlComponent,
    IImageComponent,
    IPage,
    IPremiumComponent,
    IProgressBarComponent,
    IPromptComponent,
    IRecurringAskPromptComponent,
    ISectionComponent,
    ITemplate,
    ITextComponent,
    ITributeComponent,
    IVideoComponent,
    PageDesign,
    PremiumResponseModel,
    ProjectSettings,
    ProjectSplit,
    RecurringAskOption,
    SmartGiftArrayOption,
    SmartGiftArraySettings,
} from "@/builder/interfaces/index";
import {
    Frequency,
    frequencyDisplayName,
    IntegrationType,
    PaymentMethodType,
    Selection,
} from "@/enums";
import LookupResponse from "@/interfaces/responses/LookupResponse";
import { formatCurrency } from "@/utilities/StringUtilities";
import merge from "lodash.merge";
import OrganizationDesignSettings from "../interfaces/OrganizationDesignSettings";
import AddressRequest from "../interfaces/requests/AddressRequest";
import { useGlobalStore } from "../stores/globalStore";
import { ICustomCollectionComponent } from "./components/customCollection/CustomCollectionInterface";
import { ICustomFieldComponent } from "./components/customField/CustomFieldInterface";
import { IDividerComponent } from "./components/divider/DividerInterface";
import { ISpacerComponent } from "./components/spacer/SpacerInterface";
import { EMPTY_FORM_STYLES, EMPTY_STYLES } from "./Constants";
import {
    AdminFeeType,
    Alignment,
    BuilderTypes,
    ComponentType,
    ConfirmationType,
    ContainerType,
    DeviceDisplayType,
    FieldStyle,
    FormType,
    FrequencyDisplayType,
    GiftArrayType,
    GoalBasis,
    MergeTag,
    PageType,
    PartialDisplayType,
    PaymentLayout,
    PaymentMethod,
    Position,
    ProjectDisplayType,
    PromptType,
    RecurringAskTrigger,
    SectionLayoutType,
    SmartGiftArrayBasis,
    TributeType,
} from "./enums";
import { DonorMergeTags, TransactionMergeTags } from "./enums/MergeTag";
import { useBuilderStore } from "./stores/builderStore";
import { useRenderStore } from "./stores/renderStore";

/**
 *
 * @param styles
 * @returns
 */
export const stylesToClasses = (styles: ComponentStyles) => {
    return {
        ...styles,
        color: styles.fontColor,
        backgroundColor: styles.backgroundColor,
        borderWidth: `${styles.outlineWeight}px`,
        borderRadius: `${styles.borderRadius}px`,
        borderColor: styles.outlineColor,
    };
};

/**
 *
 * @param prefix
 * @returns {string}
 */
export const generateUID = (
    prefix: string = "component_",
    randomIterations: number = 2
) =>
    `${prefix}${
        // repeat the random function to generate a unique id for the amount of iterations
        Array.from({ length: randomIterations }, () =>
            Math.random().toString(36).slice(2)
        ).join("")
    }`;

/**
 *
 * @param container
 * @param childCount
 * @param childrenOrder
 * @returns {string}
 */
export const generateSortableKey = (
    container: ContainerType | string,
    childCount: number,
    childrenOrder: string[] | number[]
) => `${container}_${childCount}_${childrenOrder.join("_")}`;

/**
 * @name: Find Path
 * @desc: finds the item's path
 **/
export const findPath = (
    array: ICustomComponent[],
    id: string,
    index: number,
    path: any[]
) => {
    if (array[index].id === id) {
        path.push({ id: array[index].id, index: index });
    } else if (array[index].components.length) {
        path.push({ id: array[index].id, index: index });

        array[index].components.forEach((_, i, a) => {
            findPath(a, id, i, path);
        });

        if (path[path.length - 1].id === array[index].id) path.pop();
    }
};

/**
 * @name: Get Index Path
 * @desc: finds and returns an array of indexes and component identifiers.
 *         this is used to determine the path of an item,
 *         and used to move or delete an item from the parent.
 * */
export const getIndexPath = (array: ICustomComponent[], id: string) => {
    const path: any[] = [];
    array.forEach((_, index, arr) => findPath(arr, id, index, path));
    return path;
};

/**
 * @name: Flatten Array
 * @desc: Flattens an array of N depth for simpler and faster searches.
 * @param: array: any[]
 * @returns: any[]
 **/
export const flattenArray = (array: any[]): any[] => {
    let newArray: any[] = [];
    for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
            newArray = newArray.concat(flattenArray(array[i]));
        } else {
            newArray.push(array[i]);
            if (Array.isArray(array[i].components)) {
                newArray = newArray.concat(flattenArray(array[i].components));
            }
        }
    }
    return newArray;
};

export const getPaymentMethodType = (paymentMethod: string) => {
    //return paymentMethod enum based on string
    if (paymentMethod === PaymentMethod.Card) {
        return PaymentMethodType.Credit;
    }

    if (paymentMethod === PaymentMethod.US_Bank_Account) {
        return PaymentMethodType.ACH;
    }

    return PaymentMethodType.Credit;
};

export const handleMergeTags = (text: string) => {
    //exit if text is null or empty
    if (!text || text.length === 0) return;

    const renderStore = useRenderStore();

    text = text.replaceAll(
        `{*${MergeTag.Donor_FirstName}*}`,
        renderStore.donor.firstName
    );
    text = text.replaceAll(
        `{*${MergeTag.Donor_LastName}*}`,
        renderStore.donor.lastName
    );
    text = text.replaceAll(
        `{*${MergeTag.Donor_OrganizationName}*}`,
        renderStore.donor.organizationName ?? ""
    );
    text = text.replaceAll(
        `{*${MergeTag.Donor_Address}*}`,
        addressToString(renderStore.donor.billingAddress)
    );
    text = text.replaceAll(
        `{*${MergeTag.Donor_Email}*}`,
        renderStore.donor.email ?? "Not Provided"
    );
    text = text.replaceAll(
        `{*${MergeTag.Donor_Phone}*}`,
        renderStore.donor.phone ?? "Not Provided"
    );
    text = text.replaceAll(
        `{*${MergeTag.Transaction_ID}*}`,
        renderStore.transactionId ?? ""
    );
    text = text.replaceAll(
        `{*${MergeTag.Transaction_Cost}*}`,
        "$" + formatCurrency(String(renderStore.gift.cost ?? "0"))
    );
    text = text.replaceAll(
        `{*${MergeTag.Transcation_NetAmount}*}`,
        renderStore.netAmount ?? "$0"
    );
    text = text.replaceAll(
        `{*${MergeTag.Transaction_TotalAmount}*}`,
        renderStore.gift.coverCosts
            ? "$" + formatCurrency(String(renderStore.totalAmountWithCosts))
            : "$" + formatCurrency(String(renderStore.totalAmount))
    );
    text = text.replaceAll(
        `{*${MergeTag.Transaction_Premium}*}`,
        premiumToString(renderStore.gift.premium)
    );
    text = text.replaceAll(
        `{*${MergeTag.Transaction_ProjectSplit}*}`,
        projectSplitsToString(renderStore.gift.projectSplits)
    );
    text = text.replaceAll(
        `{*${MergeTag.Transaction_Frequency}*}`,
        renderStore.gift.frequency
            ? frequencyDisplayName(renderStore.gift.frequency)
            : "One-Time"
    );

    //TODO: add organization donor portal to render store and handle merge tag
    //text = text.replace(MergeTag.DonorPortal_Login,);

    return text;
};

export const getMergeTagList = () => {
    const mergeTagList = [
        {
            title: "Donor",
            menu: DonorMergeTags,
        },
        {
            title: "Transaction",
            menu: TransactionMergeTags,
        },
    ];

    return mergeTagList;
};

export const getConfirmationMergeTagList = () => {
    const mergeTagList = [
        {
            title: "Donor",
            menu: DonorMergeTags,
        },
        {
            title: "Transaction",
            menu: [
                {
                    value: MergeTag.Transaction_ID,
                    title: "Transaction ID",
                },
                ...TransactionMergeTags,
            ],
        },
    ];

    return mergeTagList;
};

export const addressToString = (address: AddressRequest | null) => {
    if (!address) return "Not Provided";

    const firstBlock = () => {
        const text = [address.address1, address.address2, address.city];
        return text
            .filter((a) => a !== null && a !== "")
            .map((a) => a?.trim())
            .join(" ");
    };

    const secondBlock = () => {
        const text = [
            address.state,
            address.postal?.toString(),
            address.countryString,
        ];

        return text
            .filter((a) => a !== null && a !== "")
            .map((a) => a?.trim())
            .join(" ");
    };

    const fullAdress = [firstBlock(), secondBlock()]
        .filter((a) => a !== "")
        .map((a) => a?.trim());

    if (fullAdress.length == 0) {
        return "Not Provided";
    }

    return fullAdress.join(", ");
};

export const projectSplitsToString = (projectSplits: ProjectSplit[]) => {
    let projectSplitsString = "";

    //if there are no project splits, exit
    if (!projectSplits || projectSplits.length === 0)
        return projectSplitsString;

    const zeroAmount = "0";
    projectSplits.forEach((projectSplit, index) => {
        projectSplitsString += `${projectSplit.projectName} - $${formatCurrency(
            projectSplit.amount ?? zeroAmount
        )}`;
        if (index < projectSplits.length - 1) {
            projectSplitsString += ", ";
        }
    });

    return projectSplitsString;
};

export const premiumToString = (premium?: PremiumResponseModel | null) => {
    let premiumString = "";

    if (premium) {
        premiumString = `${premium.name}`;
    }

    return premiumString;
};

export const getFormStep = (
    parentId: string,
    components: ICustomComponent[]
) => {
    const flattenCommponents = flattenArray(components);

    if (parentId && flattenCommponents.length > 0) {
        const parentComponent = flattenCommponents.find(
            (x) => x.id === parentId
        ) as ICustomComponent;

        if (
            parentComponent !== undefined &&
            parentComponent.type === ComponentType.FormStep
        ) {
            const formStepComponent = parentComponent as IFormStepComponent;
            let form = null as IFormComponent | null;

            if (formStepComponent.parentId) {
                form = flattenCommponents.find(
                    (x) => x.id === formStepComponent.parentId
                ) as IFormComponent;

                //if form is not found, search for form in the components array
                if (form === undefined) {
                    form = flattenCommponents.find(
                        (x) => x.type === ComponentType.Form
                    ) as IFormComponent;
                }
            } else {
                form = flattenCommponents.find(
                    (x) => x.type === ComponentType.Form
                ) as IFormComponent;
            }

            //get the index of the form step in the form steps array
            const formStepIndex = form?.components.findIndex(
                (x) => x.id === formStepComponent.id
            );

            return formStepIndex;
        }

        return null;
    }

    return null;
};

export const handlePrivateApiFailure = (error: any) => {
    const globalStore = useGlobalStore();
    const store = useBuilderStore();

    if (error?.response.data[""]) {
        globalStore.addToastError(`${error?.response.data[""][0]}`);
    } else if (error?.response.data) {
        store.setModelState(error.response.data);
        store.isValid = false;
    } else {
        globalStore.addToastError(
            "Uh oh! There was an unexpected error. Please try again soon."
        );
    }
};

/**
 * @name: Set Component Defaults
 * @desc: ensures components have default values for properties that are added after the component is created.
 **/
export const setComponentDefaults = (
    components: ICustomComponent[],
    page: IPage
): void => {
    const flattenedComponents = flattenArray(components);

    for (let i = 0; i < flattenedComponents.length; i++) {
        const component = flattenedComponents[i];
        const controlsCopy = { ...component.controls };
        const stylesCopy = { ...component.styles };
        const customDataCopy = { ...component.customData };
        component.controls = {
            ...getEmptyComponent(component.type, page).controls,
            ...controlsCopy,
        };
        component.styles = {
            ...getEmptyComponent(component.type, page).styles,
            ...stylesCopy,
        };
        component.customData = {
            ...getEmptyComponent(component.type, page).customData,
            ...customDataCopy,
        };
    }
};

export const getEditorId = (id: string) => {
    return "editor_" + id;
};

export const setNewComponentIds = (components: ICustomComponent[]) => {
    const flattenedComponents = flattenArray(components);

    for (let i = 0; i < flattenedComponents.length; i++) {
        const component = flattenedComponents[i];
        component.id = generateUID();
    }
};

export const removeOneTimeComponents = (
    components: ICustomComponent[],
    usedTypes: ComponentType[]
) => {
    //get the ids of the components that match the used types inside the components array
    const usedIds = flattenArray(components)
        .filter((x) => usedTypes.includes(x.type))
        .map((x) => x.id);

    //loop through ids and delete component
    usedIds.forEach((id) => {
        deleteComponentById(components, id);
    });
};

export const deleteComponentById = (
    components: ICustomComponent[],
    id: string
) => {
    const path = getIndexPath(components, id);

    const siblingComponents =
        path.length > 1
            ? getComponentById(components, path[path.length - 2].id)?.components
            : components;

    //if the path is empty, exit, the component no longer exists
    if (path.length === 0) return;

    const currentIndex = path[path.length - 1].index;

    siblingComponents.splice(currentIndex, 1);

    //return the closest sibling component id
    const beforeSibling = siblingComponents[currentIndex - 1]?.id;
    const afterSibling = siblingComponents[currentIndex]?.id;

    return beforeSibling ? beforeSibling : afterSibling;
};

export const getComponentById = (
    components: ICustomComponent[],
    id: string
) => {
    const component = flattenArray(components).find((x) => x.id === id) as
        | ICustomComponent
        | IPromptComponent;

    return component;
};

export const hasComponentByType = (
    components: ICustomComponent[],
    type: ComponentType
) => {
    return flattenArray(components).some((x) => x.type === type);
};

export const pushErrorToComponents = (
    components: ICustomComponent[],
    error: string
) => {
    //loop through components and push error
    components.forEach((component) => {
        //only push error if it doesnt already exist
        if (component.errors && !component.errors.includes(error)) {
            component.errors.push(error);
        }
    });
};

export const removeErrorFromComponents = (
    components: ICustomComponent[],
    error: string
) => {
    //loop through components and remove error
    components.forEach((component) => {
        //only remove error if it exists
        if (component.errors && component.errors.includes(error)) {
            component.errors = component.errors.filter((x) => x !== error);
        }
    });
};

export const format = (pattern) => (value) => {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, (_, index) => v[i++] || "#");
};

export const getCookie = (name: string) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
        if (parts[1].includes(";")) {
            return parts[1].split(";").shift();
        }
    }
};

export const setDesignDefaults = (
    page: IPage,
    designSettings: OrganizationDesignSettings
): void => {
    //only set the values if they are not already set
    page.pageDesign.websiteUrl = setDesignValue(
        page.pageDesign.websiteUrl,
        designSettings.websiteUrl
    );
    page.pageDesign.logoUrl = setDesignValue(
        page.pageDesign.logoUrl,
        designSettings.logoUrl
    );
    page.pageDesign.primaryColor = setDesignValue(
        page.pageDesign.primaryColor,
        designSettings.primaryColor
    );
    page.pageDesign.secondaryColor = setDesignValue(
        page.pageDesign.secondaryColor,
        designSettings.secondaryColor
    );
    page.pageDesign.backgroundColor = setDesignValue(
        page.pageDesign.backgroundColor,
        designSettings.backgroundColor
    );
    page.pageDesign.headerColor = setDesignValue(
        page.pageDesign.headerColor,
        designSettings.headerColor
    );
    page.pageDesign.headerTextColor = setDesignValue(
        page.pageDesign.headerTextColor,
        designSettings.headerTextColor
    );
    page.pageDesign.bodyTextColor = setDesignValue(
        page.pageDesign.bodyTextColor,
        designSettings.bodyTextColor
    );
    page.pageDesign.footerColor = setDesignValue(
        page.pageDesign.footerColor,
        designSettings.footerColor
    );
    page.pageDesign.footerTextColor = setDesignValue(
        page.pageDesign.footerTextColor,
        designSettings.footerTextColor
    );
    page.pageDesign.buttonColor = setDesignValue(
        page.pageDesign.buttonColor,
        designSettings.buttonColor
    );
    page.pageDesign.buttonTextColor = setDesignValue(
        page.pageDesign.buttonTextColor,
        designSettings.buttonTextColor
    );
    page.pageDesign.buttonHoverColor = setDesignValue(
        page.pageDesign.buttonHoverColor,
        designSettings.buttonHoverColor
    );
    page.pageDesign.buttonHoverTextColor = setDesignValue(
        page.pageDesign.buttonHoverTextColor,
        designSettings.buttonHoverTextColor
    );
    page.pageDesign.linkColor = setDesignValue(
        page.pageDesign.linkColor,
        designSettings.linkColor
    );
    page.pageDesign.fieldLabelColor = setDesignValue(
        page.pageDesign.fieldLabelColor,
        designSettings.fieldLabelColor
    );
    page.pageDesign.fieldBorderColor = setDesignValue(
        page.pageDesign.fieldBorderColor,
        designSettings.fieldBorderColor
    );
    page.pageDesign.borderRadius = setDesignValue(
        page.pageDesign.borderRadius,
        designSettings.borderRadius
    );
    page.pageDesign.fontFamily = setDesignValue(
        page.pageDesign.fontFamily,
        designSettings.fontFamily
    );
};

const setDesignValue = (currentValue: string | null, value: string | null) => {
    return currentValue ? currentValue : value;
};

export const setPageCssVariables = (pageDesign: PageDesign) => {
    document.documentElement.style.setProperty(
        "--body-bg-color",
        pageDesign.backgroundColor
    );
    document.documentElement.style.setProperty(
        "--body-text-color",
        pageDesign.bodyTextColor
    );

    document.documentElement.style.setProperty(
        "--header-bg-color",
        pageDesign.headerColor
    );
    document.documentElement.style.setProperty(
        "--header-text-color",
        pageDesign.headerTextColor
    );

    document.documentElement.style.setProperty(
        "--base-font-family",
        pageDesign.fontFamily
    );

    document.documentElement.style.setProperty(
        "--link-text-color",
        pageDesign.linkColor
    );

    document.documentElement.style.setProperty(
        "--primary-color",
        pageDesign.primaryColor
    );

    document.documentElement.style.setProperty(
        "--secondary-color",
        pageDesign.secondaryColor
    );

    document.documentElement.style.setProperty(
        "--button-color",
        pageDesign.buttonColor
    );

    document.documentElement.style.setProperty(
        "--button-hover-color",
        pageDesign.buttonHoverColor
    );

    document.documentElement.style.setProperty(
        "--button-text-color",
        pageDesign.buttonTextColor
    );

    document.documentElement.style.setProperty(
        "--button-hover-text-color",
        pageDesign.buttonHoverTextColor
    );

    document.documentElement.style.setProperty(
        "--footer-bg-color",
        pageDesign.footerColor
    );

    document.documentElement.style.setProperty(
        "--footer-text-color",
        pageDesign.footerTextColor
    );

    document.documentElement.style.setProperty(
        "--field-label-color",
        pageDesign.fieldLabelColor
    );

    document.documentElement.style.setProperty(
        "--input-border-color",
        pageDesign.fieldBorderColor
    );

    document.documentElement.style.setProperty(
        "--input-border-radius",
        pageDesign.borderRadius ? pageDesign.borderRadius + "px" : "0px"
    );
};

export const getComponentFriendlyName = (type: ComponentType) => {
    switch (type) {
        case ComponentType.Header:
            return "Header";
        case ComponentType.Footer:
            return "Footer";
        case ComponentType.Section:
            return "Section";
        case ComponentType.Form:
            return "Form";
        case ComponentType.FormStep:
            return "Form Step";
        case ComponentType.TextInput:
            return "Text Input";
        case ComponentType.Text:
            return "Text";
        case ComponentType.Button:
            return "Button";
        case ComponentType.ButtonGroup:
            return "Button Group";
        case ComponentType.Icon:
            return "Icon";
        case ComponentType.Image:
            return "Image";
        case ComponentType.Video:
            return "Video";
        case ComponentType.HTML:
            return "HTML";
        case ComponentType.DonationBlock:
            return "Donation Block";
        case ComponentType.Payment:
            return "Payment Block";
        case ComponentType.Frequency:
            return "Frequency";
        case ComponentType.CoverCost:
            return "Cover Cost";
        case ComponentType.Premium:
            return "Premium";
        case ComponentType.Tribute:
            return "Tribute";
        case ComponentType.DoubleTheDonation:
            return "Double The Donation";
        case ComponentType.Spacer:
            return "Spacer";
        case ComponentType.Divider:
            return "Divider";
        default:
            return "Component";
    }
};

export const getTemplates = (
    builder: IBuilder,
    page: IPage,
    currentContainer: ContainerType
) => {
    let templates = [
        {
            id: generateUID(),
            name: "blank-section",
            label: "Blank Section",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Body],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Section, page),
        },
        {
            id: generateUID(),
            name: "blank-form",
            label: "Blank Form",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Column],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Form),
        },
        {
            id: generateUID(),
            name: "blank-form-step",
            label: "Blank Form Step",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.FormStep, page),
        },
        {
            id: generateUID(),
            name: "text",
            label: "Text",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getSecondaryTextComponent(page),
        },
        {
            id: generateUID(),
            name: "text-input",
            label: "Text Input",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getTextInputComponent(),
        },
        {
            id: generateUID(),
            name: "image",
            label: "Image",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getSecondaryImageComponent(page),
        },
        {
            id: generateUID(),
            name: "video",
            label: "Video",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getVideoComponent(page),
        },
        {
            id: generateUID(),
            name: "html",
            label: "HTML",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getHTMLComponent(page),
        },
        {
            id: generateUID(),
            name: "donation-block",
            label: "Donation Block",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getDonationInputComponent(page),
        },
        {
            id: generateUID(),
            name: "button",
            label: "Button",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getButtonComponent(page),
        },
        {
            id: generateUID(),
            name: "button-group",
            label: "Button Group",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getButtonGroupComponent(page),
        },
        {
            id: generateUID(),
            name: "icon",
            label: "Icon",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getIconComponent(page),
        },
        {
            id: generateUID(),
            name: "payment-block",
            label: "Payment Block",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getPaymentComponent(page),
        },
        {
            id: generateUID(),
            name: "frequency",
            label: "Frequency",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Frequency, page),
        },
        {
            id: generateUID(),
            name: "cover-cost",
            label: "Cover Cost",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.CoverCost, page),
        },
        {
            id: generateUID(),
            name: "premium",
            label: "Premiums",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Premium, page),
        },
        {
            id: generateUID(),
            name: "footer",
            label: "Footer",
            category: "Basic",
            validPageTypes: [PageType.Full],
            validContainers: [ContainerType.Body],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getFooterComponent(page),
        },
        {
            id: generateUID(),
            name: "tribute",
            label: "Tribute",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Tribute, page),
        },
        {
            id: generateUID(),
            name: "double-the-donation",
            label: "Double The Donation",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: IntegrationType.DoubleTheDonation,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.DoubleTheDonation, page),
        },
        {
            id: generateUID(),
            name: "spacer",
            label: "Spacer",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Spacer, page),
        },
        {
            id: generateUID(),
            name: "divider",
            label: "Divider",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.Divider, page),
        },
        {
            id: generateUID(),
            name: "progress-bar",
            label: "Progress Bar",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
                ContainerType.Prompt,
            ],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.ProgressBar, page),
        },
        {
            id: generateUID(),
            name: "anonymous-giving",
            label: "Anonymous Giving",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.AnonymousGiving, page),
        },
        {
            id: generateUID(),
            name: "recurring-ask-prompt",
            label: "Recurring Ask Prompt",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyPromptComponent(PromptType.RecurringAsk, page),
        },
        {
            id: generateUID(),
            name: "custom-field",
            label: "Custom Field",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: null,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.CustomField, page),
        },
        {
            id: generateUID(),
            name: "custom-collection",
            label: "Custom Collection",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: IntegrationType.Virtuous,
            isDisabled: false,
            disabledReason: null,
            component: getEmptyComponent(ComponentType.CustomCollection, page),
        },
    ] as ITemplate[];

    //filters templates by the current container and page type
    templates = templates
        .filter((x) => x.validContainers.indexOf(currentContainer) > -1)
        .filter((x) => x.validPageTypes.indexOf(page.pageType) > -1);

    //determine which templates are disabled
    templates.forEach((template) => {
        setTemplateState(builder, page, template);
    });

    return templates;
};

export const setTemplateState = (
    builder: IBuilder,
    page: IPage,
    template: ITemplate
) => {
    const componentType = template.component.type;

    if (template.validIntegration !== null) {
        const match = builder.integrations.some(
            (integration) =>
                integration.integrationType === template.validIntegration
        );
        if (!match) {
            template.isDisabled = true;
            template.disabledReason = "Integration not enabled";
        }
    }

    if (
        !template.component.controls.allowMultiple &&
        componentType !== ComponentType.Prompt
    ) {
        if (
            flattenArray(page.components).some((x) => x.type === componentType)
        ) {
            template.isDisabled = true;
            template.disabledReason = "Already added";
        }
    }

    if (template.validPageTypes) {
        if (!template.validPageTypes.includes(page.pageType)) {
            template.isDisabled = true;
            template.disabledReason = "Invalid Page Type";
        }
    }

    if (componentType === ComponentType.Prompt) {
        const promptComponent = template.component as IPromptComponent;
        const promptType = promptComponent.promptType;
        if (!template.component.controls.allowMultiple) {
            if (
                flattenArray(page.prompts).some(
                    (x) => x.promptType === promptType
                )
            ) {
                template.isDisabled = true;
                template.disabledReason = "Already added";
            }
        }

        if (promptType === PromptType.RecurringAsk) {
            //check if a frequency component is present on the page
            if (
                !flattenArray(page.components).some(
                    (x) => x.type === ComponentType.Frequency
                )
            ) {
                template.isDisabled = true;
                template.disabledReason = "Frequency component required";
            }
        }

        if (promptType === PromptType.PremiumAsk) {
            //check if a cover cost component is present on the page
            if (
                !flattenArray(page.components).some(
                    (x) => x.type === ComponentType.Premium
                )
            ) {
                template.isDisabled = true;
                template.disabledReason = "Premium component required";
            }
        }
    }

    return false;
};

export const getValidParentComponentTypes = (componentType: ComponentType) => {
    switch (componentType) {
        case ComponentType.Form:
            return [ComponentType.Column];

        case ComponentType.FormStep:
        case ComponentType.Confirmation:
            return [ComponentType.Form];

        //basic components
        case ComponentType.Text:
        case ComponentType.Image:
        case ComponentType.Video:
        case ComponentType.HTML:
        case ComponentType.Button:
        case ComponentType.ButtonGroup:
        case ComponentType.Icon:
        case ComponentType.Spacer:
        case ComponentType.Divider:
        case ComponentType.ProgressBar:
            return [
                ComponentType.Column,
                ComponentType.FormStep,
                ComponentType.Confirmation,
                ComponentType.Prompt,
            ];

        //form step components
        case ComponentType.TextInput:
        case ComponentType.Frequency:
        case ComponentType.DonationBlock:
        case ComponentType.Payment:
        case ComponentType.CoverCost:
        case ComponentType.Premium:
        case ComponentType.Tribute:
        case ComponentType.DoubleTheDonation:
        case ComponentType.AnonymousGiving:
        case ComponentType.CustomField:
        case ComponentType.CustomCollection:
            return [ComponentType.FormStep];

        default:
            throw new Error("Invalid component type");
    }
};

export const inputDefaults = {
    name: {
        type: InputType.Name,
        titleLabel: "Title",
        titlePlaceholder: "Title",
        firstNameLabel: "First Name",
        firstNamePlaceholder: "First",
        lastNameLabel: "Last Name",
        lastNamePlaceholder: "Last",
        middleNameLabel: "Middle Name",
        middleNamePlaceholder: "Middle",
        suffixLabel: "Suffix",
        suffixPlaceholder: "Suffix",
        businssNameLabel: "Business Name",
        businessNamePlaceholder: "Business Name",
        required: true,
    },
    email: {
        type: InputType.Email,
        label: "Email",
        placeholder: "email@example.com",
        required: true,
    },
    address: {
        type: InputType.Address,
        billingAddressLabel: "Address",
        billingAddress1Placeholder: "Street address",
        billingAddress2Placeholder: "Apartment or suite number",
        billingCityPlaceholder: "City",
        billingStatePlaceholder: "State",
        billingPostalPlaceholder: "Zip Code",
        billingCountryPlaceholder: "Country",
        required: true,
    },
};

export const removeSortableDropZone = () => {
    Array.from(document.querySelectorAll(".sortable-drop-zone")).forEach((e) =>
        e.classList.remove("sortable-drop-zone")
    );
};

const getSimpleGivingForm = (page: IPage) => {
    const formId = generateUID();
    const firstFormStepId = generateUID();
    const secondFormStepId = generateUID();
    const confirmationId = generateUID();

    return {
        ...getEmptySectionComponent(page),
        components: [
            {
                ...getEmptyColumnComponent(page),
                components: [
                    {
                        ...getEmptyComponent(ComponentType.Form, page),
                        id: formId,
                        components: [
                            {
                                ...getEmptyComponent(
                                    ComponentType.FormStep,
                                    page
                                ),
                                parentId: formId,
                                id: firstFormStepId,
                                components: [
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<h3 style="text-align: center;"><span style="font-size: 20px;">Make a Donation</span></h3>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Spacer,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            customData: {
                                                topPadding: 20,
                                                bottomPadding: 20,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Frequency,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            customData: {
                                                displayType:
                                                    FrequencyDisplayType.Tabs,
                                                buttonSize: "rd-btn-lg",
                                                defaultFrequency:
                                                    Frequency.OneTime,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.DonationBlock,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            customData: {
                                                giftArrayType: 1,
                                                giftArray: [
                                                    {
                                                        value: 10,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 25,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 50,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 100,
                                                        isSelected: false,
                                                    },
                                                ],
                                                fixedAmount: 0,
                                                projectSettings: {
                                                    showProjects: false,
                                                },
                                                useRecurringArray: true,
                                                recurringArray: [
                                                    {
                                                        value: 5,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 10,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 15,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 30,
                                                        isSelected: false,
                                                    },
                                                ],
                                                buttonSize: "rd-btn-lg",
                                            },
                                        }
                                    ),
                                ],
                                customData: {
                                    ...getEmptyComponent(
                                        ComponentType.FormStep,
                                        page
                                    ).customData,
                                    buttons: {
                                        ...getEmptyComponent(
                                            ComponentType.FormStep,
                                            page
                                        ).customData.buttons,
                                        styles: {
                                            ...getEmptyComponent(
                                                ComponentType.FormStep,
                                                page
                                            ).customData.buttons.styles,
                                            fullWidth: true,
                                        },
                                    },
                                    title: "Amount and Frequency",
                                },
                            },
                            {
                                ...getEmptyComponent(
                                    ComponentType.FormStep,
                                    page
                                ),
                                parentId: formId,
                                id: secondFormStepId,
                                components: [
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<h3 style="text-align: center;"><span style="font-size: 20px;">Make a Donation</span></h3>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.TextInput,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            customData: {
                                                ...inputDefaults.name,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.TextInput,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            customData: {
                                                ...inputDefaults.email,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Payment,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            customData: {
                                                enableWallets: true,
                                                layout: {
                                                    type: PaymentLayout.Accordion,
                                                    radios: false,
                                                    spacedAccordionItems: true,
                                                },
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<p style="text-align: center;"><span style="font-size: 14px;">Pressing "Donate" will process your payment method for a <strong>{*Transaction.Frequency*}</strong> donation of <strong>{*Transaction.TotalAmount*}</strong>.</span></p>',
                                            },
                                        }
                                    ),
                                ],
                                customData: {
                                    ...getEmptyComponent(
                                        ComponentType.FormStep,
                                        page
                                    ).customData,
                                    title: "Donor Information",
                                    buttons: {
                                        ...getEmptyComponent(
                                            ComponentType.FormStep,
                                            page
                                        ).customData.buttons,
                                        styles: {
                                            ...getEmptyComponent(
                                                ComponentType.FormStep,
                                                page
                                            ).customData.buttons.styles,
                                            fullWidth: true,
                                        },
                                    },
                                },
                            },
                            {
                                ...getEmptyComponent(
                                    ComponentType.Confirmation,
                                    page
                                ),
                                id: confirmationId,
                                parentId: formId,
                                components: [
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Icon,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                icon: "circle-check",
                                                size: 5,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Spacer,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                topPadding: 20,
                                                bottomPadding: 20,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<h2 style="text-align: center;">Thank you for your gift, {*Donor.FirstName*}!</h2>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Divider,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                alignment: Alignment.Center,
                                                topPadding: 10,
                                                bottomPadding: 40,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'Inter', sans-serif",
                                            },
                                            customData: {
                                                value: '<h6 style="text-align: center; margin-bottom: 0.6rem;"><span style="font-size: 16px;"><strong>Transaction Details</strong></span></h6><p style="text-align: center; line-height: 1.5rem;"><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.ID*}</span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.TotalAmount*}</span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.Frequency*}</span></p>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Spacer,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                topPadding: 8,
                                                bottomPadding: 8,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'Inter', sans-serif",
                                            },
                                            customData: {
                                                value: '<h6 style="text-align: center; margin-bottom: 0.6rem;"><span style="font-size: 16px;"><strong>Donor Details</strong></span></h6><p style="text-align: center; line-height: 1.5rem;"><span style="font-size: 16px;"><span style="color: rgb(116, 128, 134);">{*Donor.FirstName*}</span><span style="color: rgb(116, 128, 134);">{*Donor.LastName*}</span></span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Donor.Email*}</span></p>',
                                            },
                                        }
                                    ),
                                ],
                            },
                        ],
                        customData: {
                            ...getEmptyComponent(ComponentType.Form, page)
                                .customData,
                            buttons: {
                                ...getEmptyComponent(ComponentType.Form, page)
                                    .customData.buttons,
                                modalAlignment: Alignment.Start,
                                modal: {
                                    ...getEmptyComponent(
                                        ComponentType.Form,
                                        page
                                    ).customData.buttons.modal,
                                    styles: {
                                        ...getEmptyComponent(
                                            ComponentType.Form,
                                            page
                                        ).customData.buttons.modal.styles,
                                        fontFamily: "'DM Sans', sans-serif",
                                    },
                                    customData: {
                                        ...getEmptyComponent(
                                            ComponentType.Form,
                                            page
                                        ).customData.buttons.modal.customData,
                                        alignment: Alignment.Start,
                                    },
                                },
                                submit: {
                                    ...getEmptyComponent(
                                        ComponentType.Form,
                                        page
                                    ).customData.buttons.submit,
                                    styles: {
                                        ...getEmptyComponent(
                                            ComponentType.Form,
                                            page
                                        ).customData.buttons.submit,
                                        cssClass: "rd-btn rd-btn-primary",
                                        fullWidth: true,
                                    },
                                },
                            },
                        },
                    },
                ],
                customData: {
                    columnWidth: 12,
                    horizontalAlignment: Alignment.Center,
                    verticalAlignment: Alignment.Center,
                },
            },
        ],
    };
};

const getModalGivingForm = (page: IPage) => {
    const formId = generateUID();
    const firstFormStepId = generateUID();
    const secondFormStepId = generateUID();
    const confirmationId = generateUID();

    return {
        ...getEmptySectionComponent(page),
        components: [
            {
                ...getEmptyColumnComponent(page),
                components: [
                    {
                        ...getEmptyComponent(ComponentType.Form, page),
                        id: formId,
                        components: [
                            {
                                ...getEmptyComponent(
                                    ComponentType.FormStep,
                                    page
                                ),
                                parentId: formId,
                                id: firstFormStepId,
                                components: [
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<h3 style="text-align: center;"><span style="font-size: 20px;">Make a Donation</span></h3>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Spacer,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            customData: {
                                                topPadding: 20,
                                                bottomPadding: 20,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Frequency,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            customData: {
                                                displayType:
                                                    FrequencyDisplayType.Tabs,
                                                buttonSize: "rd-btn-lg",
                                                defaultFrequency:
                                                    Frequency.OneTime,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.DonationBlock,
                                            page
                                        ),
                                        {
                                            parentId: firstFormStepId,
                                            customData: {
                                                giftArrayType: 1,
                                                giftArray: [
                                                    {
                                                        value: 10,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 25,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 50,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 100,
                                                        isSelected: false,
                                                    },
                                                ],
                                                fixedAmount: 0,
                                                projectSettings: {
                                                    showProjects: false,
                                                },
                                                useRecurringArray: true,
                                                recurringArray: [
                                                    {
                                                        value: 5,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 10,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 15,
                                                        isSelected: false,
                                                    },
                                                    {
                                                        value: 30,
                                                        isSelected: false,
                                                    },
                                                ],
                                                buttonSize: "rd-btn-lg",
                                            },
                                        }
                                    ),
                                ],
                                customData: {
                                    ...getEmptyComponent(
                                        ComponentType.FormStep,
                                        page
                                    ).customData,
                                    buttons: {
                                        ...getEmptyComponent(
                                            ComponentType.FormStep,
                                            page
                                        ).customData.buttons,
                                        prev: {
                                            ...getEmptyComponent(
                                                ComponentType.FormStep,
                                                page
                                            ).customData.buttons.prev,
                                        },
                                        next: {
                                            ...getEmptyComponent(
                                                ComponentType.FormStep,
                                                page
                                            ).customData.buttons.next,
                                            styles: {
                                                ...getEmptyComponent(
                                                    ComponentType.FormStep,
                                                    page
                                                ).customData.buttons.next
                                                    .styles,
                                                cssClass:
                                                    "rd-btn rd-btn-primary",
                                                fullWidth: true,
                                            },
                                            customData: {
                                                ...getEmptyComponent(
                                                    ComponentType.FormStep,
                                                    page
                                                ).customData.buttons.next
                                                    .customData,
                                            },
                                        },
                                    },
                                    title: "Amount and Frequency",
                                },
                            },
                            {
                                ...getEmptyComponent(
                                    ComponentType.FormStep,
                                    page
                                ),
                                parentId: formId,
                                id: secondFormStepId,
                                components: [
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<h3 style="text-align: center;"><span style="font-size: 20px;">Make a Donation</span></h3>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.TextInput,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            customData: {
                                                ...inputDefaults.name,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.TextInput,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            customData: {
                                                ...inputDefaults.email,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Payment,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            customData: {
                                                enableWallets: true,
                                                layout: {
                                                    type: PaymentLayout.Accordion,
                                                    radios: false,
                                                    spacedAccordionItems: true,
                                                },
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: secondFormStepId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<p style="text-align: center;"><span style="font-size: 14px;">Pressing "Donate" will process your payment method for a <strong>{*Transaction.Frequency*}</strong> donation of <strong>{*Transaction.TotalAmount*}</strong>.</span></p>',
                                            },
                                        }
                                    ),
                                ],
                                customData: {
                                    ...getEmptyComponent(
                                        ComponentType.FormStep,
                                        page
                                    ).customData,
                                    title: "Donor Information",
                                    buttons: {
                                        ...getEmptyComponent(
                                            ComponentType.FormStep,
                                            page
                                        ).customData.buttons,
                                    },
                                },
                            },
                            {
                                ...getEmptyComponent(
                                    ComponentType.Confirmation,
                                    page
                                ),
                                id: confirmationId,
                                parentId: formId,
                                components: [
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Icon,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                icon: "circle-check",
                                                size: 5,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Spacer,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                topPadding: 20,
                                                bottomPadding: 20,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'DM Sans', sans-serif",
                                            },
                                            customData: {
                                                value: '<h2 style="text-align: center;">Thank you for your gift, {*Donor.FirstName*}!</h2>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Divider,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                topPadding: 10,
                                                bottomPadding: 40,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'Inter', sans-serif",
                                            },
                                            customData: {
                                                value: '<h6 style="text-align: center; margin-bottom: 0.6rem;"><span style="font-size: 16px;"><strong>Transaction Details</strong></span></h6><p style="text-align: center; line-height: 1.5rem;"><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.ID*}</span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.TotalAmount*}</span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.Frequency*}</span></p>',
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Spacer,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            customData: {
                                                topPadding: 8,
                                                bottomPadding: 8,
                                            },
                                        }
                                    ),
                                    merge(
                                        getEmptyComponent(
                                            ComponentType.Text,
                                            page
                                        ),
                                        {
                                            parentId: confirmationId,
                                            styles: {
                                                ...EMPTY_STYLES,
                                                fontFamily:
                                                    "'Inter', sans-serif",
                                            },
                                            customData: {
                                                value: '<h6 style="text-align: center; margin-bottom: 0.6rem;"><span style="font-size: 16px;"><strong>Transaction Details</strong></span></h6><p style="text-align: center; line-height: 1.5rem;"><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.ID*}</span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.TotalAmount*}</span><br><span style="font-size: 16px; color: rgb(116, 128, 134);">{*Transaction.Frequency*}</span></p>',
                                            },
                                        }
                                    ),
                                ],
                            },
                        ],
                        customData: {
                            ...getEmptyComponent(ComponentType.Form, page)
                                .customData,
                            buttons: {
                                ...getEmptyComponent(ComponentType.Form, page)
                                    .customData.buttons,
                                modalAlignment: Alignment.Start,
                                modal: {
                                    ...getEmptyComponent(
                                        ComponentType.Form,
                                        page
                                    ).customData.buttons.modal,
                                    styles: {
                                        ...getEmptyComponent(
                                            ComponentType.Form,
                                            page
                                        ).customData.buttons.modal.styles,
                                        fontFamily: "'DM Sans', sans-serif",
                                    },
                                    customData: {
                                        ...getEmptyComponent(
                                            ComponentType.Form,
                                            page
                                        ).customData.buttons.modal.customData,
                                        alignment: Alignment.Start,
                                    },
                                },
                                submit: {
                                    ...getEmptyComponent(
                                        ComponentType.Form,
                                        page
                                    ).customData.buttons.submit,
                                    styles: {
                                        ...getEmptyComponent(
                                            ComponentType.Form,
                                            page
                                        ).customData.buttons.submit.styles,
                                    },
                                },
                            },
                        },
                    },
                ],
                customData: {
                    columnWidth: 12,
                    horizontalAlignment: Alignment.Center,
                    verticalAlignment: Alignment.Center,
                },
            },
        ],
    };
};

const getDonationFormEmbedComponents = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getSimpleGivingForm(page);
    components.push(form);
    return components;
};

const getDonationModalComponents = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getModalGivingForm(page);
    components.push(form);
    return components;
};

const getPageDefaultSectionComponent = (page: IPage) => {
    return <ISectionComponent>{
        ...getEmptyComponent(ComponentType.Section, page),
        components: [
            <IColumnComponent>{
                ...getEmptyComponent(ComponentType.Column, page),
                components: [
                    <ITextComponent>{
                        ...getSecondaryTextComponent(page),
                        customData: {
                            value:
                                "<div><h1>We need more than a donor, we need a social investor.</h1><p>Join us on our mission and help grow our talented and experienced core staff, purchase technology, form strategic partnerships, design support programs for individuals and families in crisis, execute our funding strategy, and pay operational expenses.\n" +
                                "\n" +
                                "Thank you for being on mission with us.</p></div>",
                        },
                        errors: [],
                    },
                    getButtonComponent(page),
                ],
                customData: {
                    columnWidth: 6,
                    horizontalAlignment: Alignment.Start,
                    verticalAlignment: Alignment.Start,
                },
                errors: [],
            },
            {
                ...getEmptyComponent(ComponentType.Column, page),
                components: [
                    <IImageComponent>{
                        ...getEmptyComponent(ComponentType.Image, page),
                        customData: {
                            imageUrl:
                                process.env.VUE_APP_ENVIRONMENT === "local"
                                    ? "https://raisedonorsdev.azureedge.net/public/raise-donors/template/template-img.png"
                                    : process.env.VUE_APP_CDN_URL +
                                      "/public/raise-donors/template/template-img.png",
                            altText: "Picture of field worker",
                            displayType: DeviceDisplayType.All,
                            displayOnMobile: true,
                        },
                        errors: [],
                        styles: {
                            fullWidth: true,
                        },
                    },
                ],
                customData: {
                    columnWidth: 6,
                    horizontalAlignment: Alignment.Start,
                    verticalAlignment: Alignment.Start,
                },
                errors: [],
            },
        ],
        customData: {
            backgroundImage: null,
            useMobileBackgroundImage: false,
            mobileBackgroundImage: null,
            className: null,
            layoutType: SectionLayoutType.TwoColumns_50_50,
            deviceDisplay: DeviceDisplayType.All,
            spacingSize: "md",
            containerSize: "lg",
            topPadding: 0,
            bottomPadding: 0,
            storedColumns: [],
        },
        errors: [],
    };
};

const getPageSimpleGiving = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getSimpleGivingForm(page);
    components.push(form);
    return components;
};

const getPageModalGiving = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getModalGivingForm(page);
    components.push(form);
    return components;
};

const BUILDER_TYPES_MAP = {
    [PageType.Partial]: {
        [FormType.Donation]: {
            [PartialDisplayType.Embed]: BuilderTypes.EmbedSimpleGiving,
            [PartialDisplayType.Modal]: BuilderTypes.ModalGiving,
        },
        [FormType.Lead]: {
            [PartialDisplayType.Embed]: BuilderTypes.EmbedSimpleLead,
            [PartialDisplayType.Modal]: BuilderTypes.ModalLead,
        },
    },
    [PageType.Full]: {
        [FormType.Donation]: {
            [PartialDisplayType.Embed]: BuilderTypes.PageSimpleGiving,
            [PartialDisplayType.Modal]: BuilderTypes.PageModalGiving,
        },
        [FormType.Lead]: {
            [PartialDisplayType.Embed]: BuilderTypes.PageSimpleLead,
            [PartialDisplayType.Modal]: BuilderTypes.PageModalLead,
        },
    },
};

export const getDefaultComponents = (page: IPage): ICustomComponent[] => {
    const pageType = page.pageType;
    const formType = page.formType;
    const partialDisplayType = page.partialDisplayType;

    const builderType =
        BUILDER_TYPES_MAP[pageType][formType][partialDisplayType];

    switch (builderType) {
        case BuilderTypes.EmbedSimpleGiving: {
            return getDonationFormEmbedComponents(page);
        }
        case BuilderTypes.ModalGiving: {
            return getDonationModalComponents(page);
        }
        case BuilderTypes.PageSimpleGiving: {
            return getPageSimpleGiving(page);
        }
        case BuilderTypes.PageModalGiving: {
            return getPageModalGiving(page);
        }
        default:
            return [];
    }
};

/**
 * @name: Get Empty Component
 * @desc: Gets the components empty state by component type.
 **/
export const getEmptyComponent = (
    componentType: ComponentType,
    page: IPage | null = null
): ICustomComponent => {
    switch (componentType) {
        case ComponentType.Header:
            return <IHeaderComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Header,
                controls: {
                    canMove: false,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    logoUrl: page?.pageDesign?.logoUrl,
                    logoAltText: "Your Logo",
                    logoWidth: 200,
                    showDonorPortalLink: true,
                    links: [],
                },
                errors: [],
                label: "Header",
            };
        case ComponentType.Footer:
            return getFooterComponent(page) as IFooterComponent;
        case ComponentType.Section:
            return <ISectionComponent>{
                id: generateUID(),
                type: ComponentType.Section,
                parentId: null,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: true,
                },
                components: [
                    <IColumnComponent>{
                        ...getEmptyComponent(ComponentType.Column),
                        customData: {
                            columnWidth: 12,
                            horizontalAlignment: Alignment.Start,
                            verticalAlignment: Alignment.Start,
                        },
                    },
                ] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    layoutType: SectionLayoutType.OneColumn,
                    backgroundImage: null,
                    useMobileBackgroundImage: false,
                    mobileBackgroundImage: null,
                    deviceDisplay: DeviceDisplayType.All,
                    spacingSize: "md",
                    containerSize: "lg",
                    topPadding: 0,
                    bottomPadding: 0,
                    storedColumns: [],
                },
                errors: [],
                label: "Section",
            };
        case ComponentType.Column:
            return <IColumnComponent>{
                id: generateUID(),
                type: ComponentType.Column,
                controls: {
                    canMove: false,
                    canEdit: false,
                    canDelete: false,
                    allowChildren: true,
                    allowMultiple: true,
                },
                styles: {
                    ...EMPTY_STYLES,
                },
                components: [] as ICustomComponent[],
                customData: {
                    columnWidth: null,
                    horizontalAlignment: Alignment.Start,
                    verticalAlignment: Alignment.Start,
                },
                errors: [],
                label: "Column",
            };
        case ComponentType.Text:
            return <ITextComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Text,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    value: null,
                    mergeTags: null,
                    timeout: null,
                },
                errors: [],
                label: "Text",
            };
        case ComponentType.Image:
            return <IImageComponent>{
                id: generateUID(),
                type: ComponentType.Image,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                    fullWidth: true,
                },
                customData: {
                    imageUrl:
                        process.env.VUE_APP_ENVIRONMENT === "local"
                            ? "https://raisedonorsdev.azureedge.net/public/raise-donors/template/template-img.png"
                            : process.env.VUE_APP_CDN_URL +
                              "/public/raise-donors/template/template-img.png",
                    altText: "Picture of field worker",
                    alignment: null,
                    displayOnMobile: true,
                    displayType: DeviceDisplayType.All,
                },
                errors: [],
                label: "Image",
            };
        case ComponentType.Button:
            return <IButtonComponent>{
                id: generateUID(),
                type: ComponentType.Button,
                styles: {
                    ...EMPTY_STYLES,
                    cssClass: "rd-btn rd-btn-primary",
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                customData: {
                    text: "Learn More",
                    buttonSize: "",
                    iconPosition: "left",
                    action: {
                        type: ButtonActionType.Link,
                        values: "",
                        trigger: ActionTrigger.Click,
                    },
                },
                errors: [],
                label: "Button",
            };
        case ComponentType.ButtonGroup:
            return <IButtonGroupComponent>{
                id: generateUID(),
                type: ComponentType.ButtonGroup,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                customData: {},
                errors: [],
                label: "Button Group",
            };
        case ComponentType.Confirmation:
            return <IConfirmationComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Confirmation,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: false,
                    canEdit: true,
                    canDelete: false,
                    allowChildren: true,
                    allowMultiple: false,
                },
                components: [
                    <ITextComponent>{
                        id: generateUID(),
                        type: ComponentType.Text,
                        styles: {
                            ...EMPTY_STYLES,
                        },
                        controls: {
                            canMove: true,
                            canEdit: true,
                            canDelete: true,
                            allowChildren: false,
                            allowMultiple: true,
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            value:
                                page?.formType === FormType.Donation
                                    ? "<p>Thank you for your donation!</p>"
                                    : "<p>Thank you for your submission!</p>",
                        },
                        errors: [],
                    },
                ] as ICustomComponent[],
                customData: {
                    type: ConfirmationType.Custom,
                    redirectUrl: null,
                    addUrlParams: false,
                    urlParams: [],
                },
                errors: [],
                label: "Confirmation Step",
            };
        case ComponentType.Form: {
            const formId = generateUID();
            return <IFormComponent>{
                id: formId,
                parentId: null,
                type: ComponentType.Form,
                styles: {
                    ...EMPTY_FORM_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: false,
                },
                components: [
                    {
                        ...getEmptyComponent(ComponentType.FormStep, page),
                        parentId: formId,
                    },
                    {
                        ...getEmptyComponent(ComponentType.Confirmation, page),
                        parentId: formId,
                    },
                ] as ICustomComponent[],
                customData: {
                    fundraisingGoal: "",
                    expirationDate: "",
                    motivationCode: "",
                    segmentCode: "",
                    mediaOutlet: "",
                    thankYouMessage: "",
                    showDifferentMessageForRecurring: false,
                    confirmationEmailSubject: "",
                    confirmationEmailContent: "",
                    confirmationEmailAddress: "",
                    customSocialSharingMessage: false,
                    openGraphTitle: page?.name,
                    openGraphImageUrl: `${window.location.origin}/assets/img/rd-base-meta-image.png`,
                    template: "",
                    showDonorPortalLink: false,
                    showHelpAndInfoLink: false,
                    fieldStyle: FieldStyle.Default,
                    modalImage: "",
                    buttons: {
                        submit: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "rd-btn rd-btn-primary",
                                fullWidth: false,
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false,
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Donate",
                                buttonSize: "",
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.Submit,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                        modalAlignment: Alignment.Center,
                        modal: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "rd-btn rd-btn-primary",
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false,
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Donate Now",
                                buttonSize: "rd-btn-lg",
                                alignment: Alignment.Start,
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.Modal,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                    },
                },
                errors: [],
                label: "Form",
            };
        }
        case ComponentType.FormStep:
            return <IFormStepComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.FormStep,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: true,
                },
                components: [],
                customData: {
                    title: "New Step",
                    displayTitle: false,
                    buttons: {
                        prev: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "rd-btn rd-btn-previous-step",
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false,
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "",
                                buttonSize: "",
                                icon: "arrow-left",
                                iconPosition: "right",
                                action: <Action>{
                                    type: ButtonActionType.PrevStep,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                        next: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "rd-btn rd-btn-primary",
                                fullWidth: true,
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false,
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Next",
                                buttonSize: "",
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.NextStep,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                    },
                },
                errors: [],
                label: "Form Step",
            };
        case ComponentType.TextInput:
            return getTextInputComponent();
        case ComponentType.Video:
            return <IVideoComponent>{
                id: generateUID(),
                styles: {
                    ...EMPTY_STYLES,
                },
                type: ComponentType.Video,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                customData: {
                    videoUrl: null,
                },
                errors: [],
                label: "Video",
            };
        case ComponentType.HTML:
            return <IHtmlComponent>{
                id: generateUID(),
                type: ComponentType.HTML,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                customData: {
                    html: null,
                },
                errors: [],
                label: "HTML",
            };
        case ComponentType.DonationBlock:
            return <IDonationBlockComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.DonationBlock,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [] as ICustomComponent[],
                customData: {
                    giftArrayType: GiftArrayType.CustomAmount,
                    giftArray: [] as GiftArrayOption[],
                    customAmountPlaceHolder: "Enter amount",
                    fixedAmount: 0,
                    projectSettings: <ProjectSettings>{
                        showImage: false,
                        showGiftArray: false,
                        showDescription: false,
                        showCustomAmount: true,
                        allowMultipleProjects: false,
                        showPinnedProjects: true,
                        projectSelection: Selection.Include,
                        availableProjects: [] as LookupResponse[],
                        excludedProjects: [] as LookupResponse[],
                        displayType: ProjectDisplayType.Dropdown,
                        showProjects: true,
                        projectLabel: "Project",
                        projectPlaceholder: "",
                        allProjectsSearch: false,
                    },
                    useRecurringArray: false,
                    recurringArray: [] as GiftArrayOption[],
                    useSmartGiftArray: false,
                    smartGiftArraySettings: <SmartGiftArraySettings>{
                        previewAmount: 25,
                        giftArray: [] as SmartGiftArrayOption[],
                        smartArrayBasis: SmartGiftArrayBasis.SuggestedAmount,
                    },
                    buttonSize: "",
                },
                errors: [],
                label: "Donation Block",
            };
        case ComponentType.Icon:
            return <IIconComponent>{
                id: generateUID(),
                type: ComponentType.Icon,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [] as ICustomComponent[],
                customData: {
                    icon: "circle-check",
                    size: 6,
                },
                errors: [],
                label: "Icon",
            };
        case ComponentType.Payment:
            return <IPaymentComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Payment,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    currency: "usd",
                    paymentMethods: [
                        PaymentMethod.Card,
                        PaymentMethod.US_Bank_Account,
                    ],
                    enableWallets: false,
                    enablePayPal: page?.payPalGatewayId != null,
                    layout: {
                        type: PaymentLayout.Tabs,
                    },
                    locale: "auto",
                },
                errors: [],
                label: `Payment Block`,
            };
        case ComponentType.Frequency:
            return <IFrequencyComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Frequency,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    displayType: FrequencyDisplayType.Tabs,
                    availableFrequencies: [
                        Frequency.OneTime,
                        Frequency.Monthly,
                    ] as Frequency[],
                    defaultFrequency: null,
                    frequencyOrder: [] as Frequency[],
                    canChooseStartDate: false,
                    alignment: Alignment.Start,
                    checkboxText:
                        "Increase my impact, and make this a recurring gift.",
                    checkedByDefault: false,
                    buttonSize: "",
                    showIcons: false,
                    icon: null,
                    iconPosition: null,
                },
                errors: [],
                label: "Frequency",
            };
        case ComponentType.CoverCost:
            return <ICoverCostComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.CoverCost,
                styles: {
                    ...EMPTY_STYLES,
                    backgroundColor: page?.pageDesign?.primaryColor,
                    borderRadius: page?.pageDesign?.borderRadius,
                    borderColor: page?.pageDesign?.fieldBorderColor,
                    fontColor: page?.pageDesign?.bodyTextColor,
                    fontFamily: page?.pageDesign?.fontFamily,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    alignment: Alignment.Start,
                    collectCoverCost: true,
                    coverCostText:
                        "Cover the credit card processing fee so that 100% of my donation goes to the cause.",
                    checkedByDefault: false,
                    collectAdminFee: false,
                    adminAlignment: Alignment.Start,
                    adminFeeType: AdminFeeType.Percentage,
                    adminFeeAmount: 0,
                    adminFeeText: "Cover the administration fee of {fee}?",
                    adminFeeProject: null,
                    adminFeeCheckedByDefault: false,
                },
                errors: [],
                label: "Cover Cost",
            };
        case ComponentType.Tribute:
            return <ITributeComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Tribute,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    isChecked: false,
                    alignment: Alignment.Start,
                    tributeType: TributeType.Both,
                    tributeOptInText:
                        "Dedicate my donation in honor or in memory of someone.",
                    tributeInformationLabel: "Tribute Information",
                    showTributeAddress: true,
                    hasAcknowledgement: true,
                    acknowledgeeInformationLabel: "Acknowledgee Information",
                    hasEmailAcknowledgement: true,
                    hasPostalAcknowledgement: true,
                },
                errors: [],
                label: "Tribute",
            };
        case ComponentType.DoubleTheDonation:
            return <ICustomComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.DoubleTheDonation,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    label: "Search for your employer to match your donation",
                },
                errors: [],
                label: "Double The Donation",
            };
        case ComponentType.Premium:
            return <IPremiumComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Premium,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    optInButton: <IButtonComponent>{
                        id: generateUID(),
                        type: ComponentType.Button,
                        styles: {
                            ...EMPTY_STYLES,
                            cssClass: "rd-btn rd-btn-primary",
                        },
                        controls: {
                            canMove: false,
                            canEdit: true,
                            canDelete: false,
                            allowChildren: false,
                            allowMultiple: false,
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            text: "Opt In",
                            buttonSize: "",
                            icon: null,
                            action: <Action>{
                                type: ButtonActionType.Link,
                                trigger: ActionTrigger.Click,
                                values: null,
                            },
                        },
                        errors: [],
                    },
                    optOutButton: <IButtonComponent>{
                        id: generateUID(),
                        type: ComponentType.Button,
                        styles: {
                            ...EMPTY_STYLES,
                            cssClass: "rd-btn rd-btn-neutral font-normal",
                        },
                        controls: {
                            canMove: false,
                            canEdit: true,
                            canDelete: false,
                            allowChildren: false,
                            allowMultiple: false,
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            text: "Opt Out",
                            buttonSize: "",
                            icon: null,
                            action: <Action>{
                                type: ButtonActionType.Link,
                                trigger: ActionTrigger.Click,
                                values: null,
                            },
                        },
                        errors: [],
                    },
                    showName: true,
                    showSku: true,
                    showImage: true,
                    showDescription: true,
                    showOptIn: true,
                    showOptOut: true,
                    premiumArray: [] as LookupResponse[],
                },
                errors: [],
                label: "Premium Block",
            };
        case ComponentType.Spacer:
            return <ISpacerComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Spacer,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [],
                customData: {
                    topPadding: 10,
                    bottomPadding: 10,
                },
                errors: [],
                label: "Spacer",
            };
        case ComponentType.Divider:
            return <IDividerComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Divider,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [],
                customData: {
                    alignment: null,
                    topPadding: 10,
                    bottomPadding: 10,
                    dividerColor: "#DCDBD9",
                    dividerStyle: "solid",
                    dividerWidth: 75,
                    dividerWeight: 1,
                },
                errors: [],
                label: "Divider",
            };
        case ComponentType.ProgressBar:
            return <IProgressBarComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.ProgressBar,
                styles: {
                    ...EMPTY_STYLES,
                    outlineWeight: null,
                    outlineColor: null,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    label: "Progress Bar",
                    labelPosition: Position.Top,
                    goalBasis: GoalBasis.AmountRaised,
                    addAmount: null,
                    barHeight: 20,
                    displayThresholdEnabled: false,
                    displayThreshold: null,
                },
                errors: [],
                label: "Progress Bar",
            };
        case ComponentType.AnonymousGiving:
            return <IAnonymousGivingInterface>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.AnonymousGiving,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    anonymousText: "Make my donation anonymous.",
                    alignment: Alignment.Start,
                },
                errors: [],
                label: "Anonymous Giving",
            };
        case ComponentType.CustomField:
            return <ICustomFieldComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.CustomField,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [],
                customData: {
                    selectedCustomField: null,
                    entityType: null,
                    dataType: null,
                    formatter: null,
                    label: "Custom Field",
                    required: false,
                    hidden: false,
                    placeholder: "",
                    defaultValue: "",
                    isConditional: false,
                    displayConditions: [],
                },
                errors: [],
                label: "Custom Field",
            };
        case ComponentType.CustomCollection:
            return <ICustomCollectionComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.CustomCollection,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true,
                },
                components: [],
                customData: {
                    selectedCustomCollection: null,
                    entityType: null,
                    label: "Custom Collection",
                    showAllFields: true,
                    selectedFields: [],
                    allowMultipleResponses: false,
                },
                errors: [],
                label: "Custom Collection",
            };
        default:
            console.error(
                "Uh oh, looks like someone forgot to add the component type to the getDefaultComponent list."
            );
            return {} as ICustomComponent;
    }
};

export const getEmptyPromptComponent = (
    promptType: PromptType,
    page: IPage | null = null
): IPromptComponent => {
    switch (promptType) {
        case PromptType.RecurringAsk:
            return <IRecurringAskPromptComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Prompt,
                promptType: PromptType.RecurringAsk,
                promptDecision: null,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: false,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: false,
                },
                components: [
                    <ITextComponent>{
                        id: generateUID(),
                        type: ComponentType.Text,
                        styles: {
                            ...EMPTY_STYLES,
                        },
                        controls: {
                            canMove: true,
                            canEdit: true,
                            canDelete: true,
                            allowChildren: false,
                            allowMultiple: true,
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            value: "<h3>Would you consider supporting us on a monthly basis?</h3><p>Our monthly partners are the backbone of our support team. By switching your donation to a recurring monthly amount, you’ll be making a profound impact many times over. We’ll keep you up to date regularly with how your contributions are being put to use.</p>",
                        },
                        errors: [],
                    },
                ],
                customData: {
                    asks: [
                        {
                            multiplier: 0.25,
                            frequency: Frequency.Monthly,
                            button: <IButtonComponent>{
                                id: generateUID(),
                                type: ComponentType.Button,
                                styles: {
                                    ...EMPTY_STYLES,
                                    cssClass: "rd-btn rd-btn-primary",
                                },
                                controls: {
                                    canMove: false,
                                    canEdit: true,
                                    canDelete: false,
                                    allowChildren: false,
                                    allowMultiple: false,
                                },
                                components: [] as ICustomComponent[],
                                customData: {
                                    text: "",
                                    buttonSize: "rd-btn-lg",
                                    icon: null,
                                    action: <Action>{
                                        type: ButtonActionType.Custom,
                                        trigger: ActionTrigger.Click,
                                        values: null,
                                    },
                                },
                                errors: [],
                            },
                        },
                        {
                            multiplier: 0.5,
                            frequency: Frequency.Monthly,
                            button: <IButtonComponent>{
                                id: generateUID(),
                                type: ComponentType.Button,
                                styles: {
                                    ...EMPTY_STYLES,
                                    cssClass: "rd-btn rd-btn-primary",
                                },
                                controls: {
                                    canMove: false,
                                    canEdit: true,
                                    canDelete: false,
                                    allowChildren: false,
                                    allowMultiple: false,
                                },
                                components: [] as ICustomComponent[],
                                customData: {
                                    text: "",
                                    buttonSize: "rd-btn-lg",
                                    icon: null,
                                    action: <Action>{
                                        type: ButtonActionType.Custom,
                                        trigger: ActionTrigger.Click,
                                        values: null,
                                    },
                                },
                                errors: [],
                            },
                        },
                    ] as RecurringAskOption[],
                    trigger: RecurringAskTrigger.AfterFrequencyStep,
                    minGiftAmount: 5,
                    maxGiftAmount: 300,
                    optOutButton: <IButtonComponent>{
                        id: "recurring-ask-opt-out",
                        type: ComponentType.Button,
                        styles: {
                            ...EMPTY_STYLES,
                            cssClass: "rd-btn rd-btn-neutral font-normal",
                        },
                        controls: {
                            canMove: false,
                            canEdit: true,
                            canDelete: false,
                            allowChildren: false,
                            allowMultiple: false,
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            text: "No thanks, keep my one-time gift!",
                            buttonSize: "rd-btn-lg",
                            icon: null,
                            action: <Action>{
                                type: ButtonActionType.Custom,
                                trigger: ActionTrigger.Click,
                                values: null,
                            },
                        },
                        errors: [],
                    },
                },
                errors: [],
                label: "Recurring Ask Prompt",
            };
        default:
            console.error(
                "Uh oh, looks like someone forgot to add the prompt type to the getEmptyPromptComponent list."
            );
            return {} as IPromptComponent;
    }
};

export const getFrequencyOptions = () => {
    return [
        {
            value: Frequency.OneTime,
            label: frequencyDisplayName(Frequency.OneTime),
        },
        {
            value: Frequency.Weekly,
            label: frequencyDisplayName(Frequency.Weekly),
        },
        {
            value: Frequency.EveryOtherWeek,
            label: frequencyDisplayName(Frequency.EveryOtherWeek),
        },
        {
            value: Frequency.Monthly,
            label: frequencyDisplayName(Frequency.Monthly),
        },
        {
            value: Frequency.Quarterly,
            label: frequencyDisplayName(Frequency.Quarterly),
        },
        {
            value: Frequency.BiAnnualy,
            label: frequencyDisplayName(Frequency.BiAnnualy),
        },
        {
            value: Frequency.Annualy,
            label: frequencyDisplayName(Frequency.Annualy),
        },
    ];
};

/**
 * TESTING DEFAULT COMPONENTS
 **/
const getSecondaryTextComponent = (page: IPage) =>
    <ITextComponent>{
        ...getEmptyComponent(ComponentType.Text, page),
        customData: {
            value: "<div><h2>How can you help?</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p></div>",
        },
    };

const getHTMLComponent = (page: IPage) => ({
    ...getEmptyComponent(ComponentType.HTML, page),
    customData: {
        html: "<h1>hello world</h1>",
    },
});

const getDonationInputComponent = (page: IPage) => ({
    ...getEmptyComponent(ComponentType.DonationBlock, page),
    label: "Donation<span>Block</span>",
});

const getVideoComponent = (page: IPage) => ({
    ...getEmptyComponent(ComponentType.Video, page),
    customData: {
        videoUrl: "https://www.youtube.com/embed/MzYD56hKF-8",
    },
});

export const getEmptyColumnComponent = (page: IPage) =>
    <IColumnComponent>{
        ...getEmptyComponent(ComponentType.Column, page),
        customData: {
            horizontalAlignment: Alignment.Start,
            verticalAlignment: Alignment.Start,
        },
    };

export const getEmptySectionComponent = (page: IPage) =>
    <ISectionComponent>{
        ...getEmptyComponent(ComponentType.Section, page),
        components: [] as ICustomComponent[],
    };

const getSecondaryImageComponent = (page: IPage) =>
    <IImageComponent>{
        ...getEmptyComponent(ComponentType.Image, page),
        customData: {
            altText: "Image",
            displayType: DeviceDisplayType.All,
            displayOnMobile: true,
        },
    };

export const getCreditCardIssuer = (number: string) => {
    // visa
    let re = new RegExp("^4");
    if (number.match(re) != null) return "visa";

    // mastercard
    if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
            number
        )
    )
        return "mastercard";

    // amex
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "amex";

    // discover
    re = new RegExp(
        "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "discover";

    return "";
};
