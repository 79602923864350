import { Toast, ToastType } from "@/components/shared/toast/interfaces";
import { AppUrls } from "@/constants";
import { ApiUrls } from "@/constants/ApiUrls";
import { PermissionHelper } from "@/helpers/PermissionHelper";
import Organization from "@/interfaces/Organization";
import Permission from "@/interfaces/Permission";
import UserContext from "@/interfaces/account/UserContext";
import router from "@/router";
import { LocalStorage_Prefix, LocalStorageManager } from "@/utilities/LocalStorageManager";
import axios from "axios";
import { defineStore } from "pinia";
import { SubscriptionType } from "@/enums";

export const useGlobalStore = defineStore("global", {
    state() {
        return {
            timeToLive: 7 * 24 * 60 * 60 * 1000, // 7 days
            isAuthenticated: false,
            isLoading: false,
            isNavPinned: false,
            currentOrganization: null as Organization | null,
            userContext: null as UserContext | null,
            permissions: [] as Permission[],
            toasts: [] as Toast[],
            currentRoute: null as string | null,
            assemblyVersion: "",
        };
    },
    actions: {
        checkAuthentication() {
            //check for local auth cookie
            const authCookieName = "isAuthenticated=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(authCookieName) == 0) {
                    this.isAuthenticated = true;
                    return true;
                }
            }

            this.removeAuthenticated();
            return false;
        },

        addAuthenticated() {
            //add auth cookie
            const d = new Date();
            //expire in 7 days
            d.setTime(d.getTime() + this.timeToLive);
            const expires = "expires=" + d.toUTCString();
            document.cookie =
                "isAuthenticated=true;" +
                expires +
                "; path=/; secure; samesite=strict;";

            this.isAuthenticated = true;
        },

        removeAuthenticated() {
            //remove auth
            document.cookie =
                "isAuthenticated=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict;";

            this.isAuthenticated = false;
            this.permissions = [];
            this.userContext = null;
        },

        async loadCurrentOrganization() {
            if (!this.isAuthenticated) return;

            await axios
                .get<Organization>(ApiUrls.Account.CurrentOrganization, {
                    withCredentials: true,
                })
                .then((response) => {
                    this.setCurrentOrganization(response.data);
                    this.setAssemblyVersion(response.data.assemblyVersion);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.removeAuthenticated();
                        router.push(AppUrls.Account.Login);
                    }

                    console.error(error);
                });
        },

        async getCurrentOrganization() {
            if (!this.currentOrganization) {
                await this.loadCurrentOrganization();
            }

            return this.currentOrganization;
        },

        setCurrentOrganization(currentOrg: Organization | null) {
            this.currentOrganization = currentOrg;
        },

        setAssemblyVersion(version: string) {
            this.assemblyVersion = version;
        },

        async loadUserContext() {
            if (!this.isAuthenticated) return;

            await axios
                .get<UserContext>(ApiUrls.Account.UserData, {
                    withCredentials: true,
                })
                .then((response) => {
                    this.setUserContext(response.data);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.removeAuthenticated();
                        router.push(AppUrls.Account.Login);
                    }

                    console.error(error);
                });
        },

        async getUserContext() {
            if (!this.userContext) {
                await this.loadUserContext();
            }

            return this.userContext;
        },

        setUserContext(context: UserContext | null) {
            this.userContext = context;
        },

        setCurrentRoute(route: string | null) {
            this.currentRoute = route;
        },

        async loadPermissions() {
            if (!this.isAuthenticated) return;

            await axios
                .get(ApiUrls.Account.Permissions, { withCredentials: true })
                .then((response) => {
                    this.setPermissions(response.data);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.removeAuthenticated();
                        router.push(AppUrls.Account.Login);
                    }

                    console.error(error);
                });
        },

        setPermissions(permissions: Permission[]) {
            this.permissions = permissions;
        },

        async hasPermission(permission: Permission) {
            if (this.permissions.length === 0) {
                //get the user's permissions
                await this.loadPermissions();
            }

            const hasPermission = PermissionHelper.HasPermission(
                this.permissions,
                permission
            );

            return hasPermission;
        },

        hasSubscription(subscription: SubscriptionType) {
            if (!this.currentOrganization) {
                return false;
            }

            return this.currentOrganization.subscriptions.includes(
                subscription
            );
        },

        async hasPermissions(permissions: Permission[]) {
            if (this.permissions.length === 0) {
                //get the user's permissions
                await this.loadPermissions();
            }

            const hasPermission = PermissionHelper.HasPermissions(
                this.permissions,
                permissions
            );

            return hasPermission;
        },

        async isSystemAdmin() {
            if (this.permissions.length === 0) {
                //get the user's permissions
                await this.loadPermissions();
            }

            const isSystemAdmin = PermissionHelper.IsSystemAdmin(
                this.permissions
            );
            return isSystemAdmin;
        },

        async isVirtualTerminalUser() {
            if (this.permissions.length === 0) {
                //get the user's permissions
                await this.loadPermissions();
            }

            const isVirtualTerminalUser =
                PermissionHelper.IsVirtualTerminalUser(this.permissions);
            return isVirtualTerminalUser;
        },

        async shouldEnableTwoFactor(): Promise<boolean> {
            if (!this.currentOrganization) {
                await this.loadCurrentOrganization();
            }

            if (!this.userContext) {
                await this.loadUserContext();
            }

            const hasMfaEnabled = this.userContext?.twoFactorEnabled ?? false;
            if (hasMfaEnabled) {
                return false;
            }

            const isSystemAdmin = await this.isSystemAdmin();
            const requireTwoFactor =
                this.currentOrganization?.requireTwoFactor ?? false;
            return isSystemAdmin || requireTwoFactor;
        },

        setIsNavPinned(pinned: boolean) {
            this.isNavPinned = pinned;

            //store in local storage
            const localStorageManager = new LocalStorageManager(LocalStorage_Prefix.User);
            localStorageManager.setItem("isNavPinned", pinned.toString());
        },

        checkIsNavPinned() {
            //check if pinned in local storage
            const localStorageManager = new LocalStorageManager(LocalStorage_Prefix.User);
            const isNavPinned = localStorageManager.getItem("isNavPinned");

            //if found, set the value
            if (isNavPinned) {
                this.isNavPinned = isNavPinned === "true";
            }

            return this.isNavPinned;
        },

        addToastSuccess(text: string) {
            this.addToast(text, ToastType.Success);
        },

        addToastError(text: string) {
            this.addToast(text, ToastType.Error);
        },

        addToast(
            text: string,
            type?: ToastType | null,
            title?: string | null,
            duration?: number | null,
            hasIcon?: boolean | null,
            iconClass?: string | null,
            hasCloseButton?: boolean | null
        ) {
            const toast: Toast = {
                id:
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15),
                text: text,
                title: title ?? null,
                type: type ?? ToastType.Info,
                duration: duration ?? 5000,
                hasIcon: hasIcon ?? false,
                iconClass: iconClass ?? "",
                hasCloseButton: hasCloseButton ?? true,
            };

            this.toasts.push(toast);
        },

        clearToast(id: string) {
            const index = this.toasts.findIndex((toast) => toast.id === id);
            this.toasts.splice(index, 1);
        },

        showZendesk() {
            // @ts-ignore
            if (window.zE) {
                // @ts-ignore
                window.zE("webWidget", "show");
            }
        },

        openZendesk() {
            // @ts-ignore
            if (window.zE) {
                // @ts-ignore
                window.zE("webWidget", "open");
            }
        },

        hideZendesk() {
            // @ts-ignore
            if (window.zE) {
                // @ts-ignore
                window.zE("webWidget", "hide");
            }
        },

        isAdmin() {
            return PermissionHelper.IsAdmin(this.permissions);
        },

        userRole() {
            const isAccountOwner = PermissionHelper.isAccountOwner(
                this.permissions
            );
            if (isAccountOwner) return "Account Owner";

            const isAdmin = this.isAdmin();
            if (isAdmin) return "Admin";

            return "Non Admin";
        },
    },
});
