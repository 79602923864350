import {
    flattenArray,
    getComponentById,
    getCookie,
    getFormStep,
    hasComponentByType,
    setPageCssVariables,
} from "@/builder/BuilderUtilities";
import {
    AddressType,
    CustomFieldDataType,
    CustomFieldEntityType,
    Frequency,
    frequencyDisplayName,
    IntegrationType,
    MerchantType,
    PaymentMethodType,
    stringToFrequency,
    SubscriptionType,
} from "@/enums";
import { publicApiService } from "@/services/publicApiService";
import {
    TrackingEventType,
    trackingPixelService,
} from "@/services/trackingPixelService";
import { amountToNumber } from "@/utilities/NumberUtilities";
import {
    decodeJson,
    decodeString,
    formatCurrency,
    sanitizeName,
} from "@/utilities/StringUtilities";
import {
    PaymentMethodCreateParams,
    Stripe,
    StripeElements,
    StripePaymentElement,
} from "@stripe/stripe-js";
import { defineStore } from "pinia";
import { nextTick } from "vue";
import { Toast, ToastType } from "../../components/shared/toast/interfaces";
import { CostRequest, Tribute } from "../../interfaces";
import AddressRequest from "../../interfaces/requests/AddressRequest";
import { ButtonActionType } from "../components/button/ButtonInterface";
import {
    ComponentType,
    ConfirmationType,
    ConfirmationUrlParams,
    PageType,
    PartialDisplayType,
    PromptDecision,
    PromptType,
    RecurringAskTrigger,
} from "../enums";
import {
    AnetPayment,
    CustomCollectionResponse,
    CustomFieldResponse,
    DonateDonorRequest,
    DonateRequest,
    DonateResponse,
    Gift,
    GoogleUtmRequest,
    ICustomComponent,
    IFormStepComponent,
    IPage,
    IPromptComponent,
    IRecurringAskPromptComponent,
    OrganizationModel,
    PageResponse,
    PremiumResponseModel,
    ProjectSplit,
    PublicIntegration,
    RaiseDonorsGlobal,
    StepError,
    VisitorGivingResponse,
} from "../interfaces";
import { CookieAcceptance } from "@/components/shared/cookie/CookieAcceptance";

declare global {
    interface Window {
        RaiseDonorsForms?: RaiseDonorsGlobal;
    }
}

export interface RenderStore {
    organization: OrganizationModel | null;
    step: number;
    maxSteps: number;
    showModal: boolean;
    builderMode: boolean;
    previewMode: boolean;
    environment: string;
    pageResponse: PageResponse;
    page: IPage;
    donor: DonateDonorRequest;
    hasTribute: boolean;
    tribute: Tribute;
    gift: Gift;
    doubleTheDonationCompanyId: string | null;
    doubleTheDonationEnteredText: string | null;
    validating: boolean;
    validateInputStep: number | null;
    modelState: any;
    errors: StepError[];
    toasts: Toast[];
    organizationId: number | null;
    visitorId: string | null;
    success: boolean;
    transactionId: string | null;

    isLoading: boolean;
    loadedFonts: Set<string>;
    isProcessing: boolean;

    visitorGiving: VisitorGivingResponse | null;
    submissionRequiresAction: boolean;
    paymentClientSecret: string | null;
    paymentId: string | null;
    activePrompt: IPromptComponent | null;
    usingWallet: boolean;
    usingPayPal: boolean;
    payPalApproved: boolean;
    clientId: string | null;
    googleUtm: GoogleUtmRequest;
    customFieldResponses: CustomFieldResponse[];
    customCollectionResponses: CustomCollectionResponse[];

    stripe: Stripe | null;
    stripeElements: StripeElements | null;
    paymentElement: StripePaymentElement | null;
    paymentElementError: string | null;
    paypalPaymentSource: string | null;
    anetPayment: AnetPayment;
    globalObject: RaiseDonorsGlobal;
    cookieAcceptance: CookieAcceptance | null;
}

export const useRenderStore = defineStore("render", {
    state(): RenderStore {
        return {
            organization: null,
            step: 0,
            maxSteps: 0,
            showModal: false,
            builderMode: false,
            previewMode: false,
            environment: "local",
            isLoading: false, //used for loading page elements or data
            loadedFonts: new Set<string>(),
            isProcessing: false, //used for processing a payment
            success: false,
            transactionId: null,
            organizationId: null,
            doubleTheDonationCompanyId: null,
            doubleTheDonationEnteredText: null,
            pageResponse: {} as PageResponse,
            page: {
                components: [] as ICustomComponent[],
                prompts: [] as IPromptComponent[],
                segmentOverrideCode: null,
                projectOverrideCode: null,
            } as IPage,
            donor: {
                title: null,
                firstName: "",
                middleName: null,
                lastName: "",
                suffix: null,
                email: "",
                phone: null,
                phoneOptIn: false,
                isOrganization: false,
                organizationName: null,
                crmKey: null,
                billingAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: null,
                    addressType: AddressType.Billing,
                } as AddressRequest,
                shippingAddress: null,
            },
            hasTribute: false,
            tribute: {
                isInHonorOf: true,
                isInMemoryOf: false,
                NameOrOccasion: "",
                tributeFirstName: "",
                tributeLastName: "",
                tributeAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: null,
                    addressType: AddressType.Billing,
                } as AddressRequest,
                acknowledgeeFirstName: "",
                acknowledgeeLastName: "",
                sendByEmail: false,
                acknowledgeeEmailAddress: "",
                sendByPostal: false,
                acknowledgeeAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: null,
                    addressType: AddressType.Billing,
                } as AddressRequest,
                message: "",
            },
            gift: {
                isAnonymous: false,
                amount: "",
                cost: null,
                payPalCost: null,
                paymentMethodId: null,
                paymentMethodType: null,
                frequency: null,
                creditCardType: "",
                comments: "",
                startDate: null,
                coverCosts: false,
                coverAdminFee: false,
                adminFeeAmount: null,
                adminFeeType: null,
                adminFeeProject: null,
                adminFee: null,
                projectSplits: [] as ProjectSplit[],
                premium: null,
            },
            visitorId: null,
            validating: false,
            validateInputStep: null,
            modelState: {},
            errors: [],
            toasts: [] as Toast[],

            visitorGiving: null,
            submissionRequiresAction: false,
            paymentClientSecret: null,
            paymentId: null,
            clientId: null,
            activePrompt: null,
            usingWallet: false,
            usingPayPal: false,
            payPalApproved: false,
            googleUtm: {
                utmSource: null,
                utmMedium: null,
                utmCampaign: null,
                utmTerm: null,
                utmContent: null,
            },
            customFieldResponses: [],
            customCollectionResponses: [],

            stripe: null,
            stripeElements: null,
            paymentElement: null,
            paymentElementError: null,
            paypalPaymentSource: null,
            anetPayment: {
                cardCode: "",
                cardNumber: "",
                expDate: "",
                accountNumber: "",
                accountType: "",
                routingNumber: "",
            },
            globalObject: {} as RaiseDonorsGlobal,
            cookieAcceptance: null,
        };
    },
    getters: {
        apiBaseUrl: (state) => {
            switch (state.environment) {
                case "local":
                    return "https://localhost:7236/api";
                case "development":
                    return "https://dev-give.raisedonors.com/api";
                case "demo":
                    return "https://demo-give.raisedonors.com/api";
                case "production":
                default:
                    return "https://public-api.raisedonors.com/api";
            }
        },

        cdnUrl: (state) => {
            switch (state.environment) {
                case "local":
                    return "https://raisedonorsdev.azureedge.net";
                case "development":
                    return "https://raisedonorsdev.azureedge.net";
                case "demo":
                    return "https://raisedonorsqa.azureedge.net";
                case "production":
                default:
                    return "https://raisedonorsprod.azureedge.net";
            }
        },

        trackingPixelUrl: (state) => {
            switch (state.environment) {
                case "local":
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.dev.js";
                case "development":
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.dev.js";
                case "demo":
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.qa.js";
                case "production":
                default:
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.min.js";
            }
        },

        amount: (state) => {
            let amount = amountToNumber(state.gift.amount);

            if (state.gift.projectSplits.length > 0) {
                amount = 0;

                for (let i = 0; i < state.gift.projectSplits.length; i++) {
                    const projectSplit = state.gift.projectSplits[i];
                    const projectSplitAmount = amountToNumber(
                        projectSplit.amount
                    );

                    if (isNaN(projectSplitAmount)) continue;

                    amount += projectSplitAmount;
                }
            }

            return amount;
        },

        costs: (state) => {
            return state.usingPayPal ? state.gift.payPalCost : state.gift.cost;
        },

        netAmount: (state) => {
            // @ts-ignore
            const totalAmount = amountToNumber(state.totalAmount ?? "0");
            const totalAmountWithCosts = amountToNumber(
                // @ts-ignore
                state.totalAmountWithCosts ?? "0"
            );
            const amount = state.gift.coverCosts
                ? totalAmountWithCosts
                : totalAmount;
            const cost = amountToNumber(state.gift.cost ?? "0");

            const netAmount = formatCurrency(String(amount - cost)) ?? "0";
            return "$" + netAmount;
        },

        totalAmount: (state) => {
            // @ts-ignore
            let totalAmount = state.amount;
            totalAmount += state.gift.coverAdminFee
                ? amountToNumber(state.gift.adminFee ?? "0")
                : 0;

            return totalAmount;
        },

        totalAmountWithCosts: (state) => {
            // @ts-ignore
            let totalAmount = amountToNumber(state.totalAmount ?? "0");
            // @ts-ignore
            const costs = amountToNumber(state.costs ?? "0");

            if (state.gift.coverCosts) {
                totalAmount += costs;
            }

            return totalAmount;
        },

        submitButtonAmount: (state) => {
            // @ts-ignore
            if (state.amount > 0) {
                if (state.gift.coverCosts) {
                    return (
                        // @ts-ignore
                        "$" + formatCurrency(String(state.totalAmountWithCosts))
                    );
                }

                // @ts-ignore
                return "$" + formatCurrency(String(state.totalAmount));
            } else {
                return "";
            }
        },

        isProjectActive: (state) => {
            return (projectId) =>
                state.gift.projectSplits.find(
                    (project) => project.projectId === projectId
                );
        },

        isPremiumActive: (state) => {
            return (premiumId: string) => {
                return state.gift.premium?.id.toString() === premiumId;
            };
        },

        isRecurring: (state) => {
            return (
                state.gift.frequency != null &&
                state.gift.frequency !== Frequency.OneTime
            );
        },

        allComponents(state: RenderStore) {
            //combine components and prompts
            return flattenArray([
                ...state.page.components,
                ...state.page.prompts,
            ]) as ICustomComponent[];
        },

        getComponent: (state: RenderStore) => {
            return (id: string) => {
                // @ts-ignore
                return getComponentById(state.allComponents, id);
            };
        },

        getFormStep: (state: RenderStore) => {
            return (componentId: string) => {
                // @ts-ignore
                const component = state.getComponent(componentId);
                if (!component || !component.parentId) return null;

                const formStep = getFormStep(
                    component.parentId,
                    state.page.components
                );

                return formStep;
            };
        },

        getFormStepByIndex: (state: RenderStore) => {
            return (stepNumber: number) => {
                // @ts-ignore
                const formSteps = state.allComponents.filter(
                    (x) => x.type === ComponentType.FormStep
                );

                if (formSteps.length === 0) return null;
                return formSteps[stepNumber];
            };
        },

        getActivePrompt: (state: RenderStore) => {
            return (isSubmitting: boolean) => {
                const prompts = state.page.prompts.filter(
                    (x) => !x.promptDecision
                );
                if (!prompts || prompts.length === 0) return null;

                if (!isSubmitting) {
                    //logic for prompts that activate on step changes
                    const recurringAskPrompt = prompts.find(
                        (x) => x.promptType === PromptType.RecurringAsk
                    ) as IRecurringAskPromptComponent;
                    if (
                        recurringAskPrompt &&
                        (recurringAskPrompt.customData.trigger ===
                            RecurringAskTrigger.AfterFrequencyStep ||
                            recurringAskPrompt.customData.trigger ===
                                RecurringAskTrigger.Both)
                    ) {
                        // @ts-ignore
                        const stepComponents = state.allComponents.filter(
                            (x) => x.type === ComponentType.FormStep
                        ) as IFormStepComponent[];
                        if (stepComponents) {
                            const currentStepComponent =
                                stepComponents[state.step];
                            if (currentStepComponent) {
                                const hasFrequencyComponent =
                                    hasComponentByType(
                                        currentStepComponent.components,
                                        ComponentType.Frequency
                                    );
                                if (
                                    hasFrequencyComponent &&
                                    (state.gift.frequency ===
                                        Frequency.OneTime ||
                                        !state.gift.frequency) &&
                                    // @ts-ignore
                                    state.amount >=
                                        recurringAskPrompt.customData
                                            .minGiftAmount &&
                                    // @ts-ignore
                                    state.amount <=
                                        recurringAskPrompt.customData
                                            .maxGiftAmount
                                ) {
                                    return recurringAskPrompt;
                                }
                            }
                        }
                    }

                    return null;
                } else {
                    //logic for prompts that activate on submission
                    const recurringAskPrompt = prompts.find(
                        (x) => x.promptType === PromptType.RecurringAsk
                    ) as IRecurringAskPromptComponent;
                    if (recurringAskPrompt) {
                        // @ts-ignore
                        const hasFrequencyComponent = hasComponentByType(
                            // @ts-ignore
                            state.allComponents,
                            ComponentType.Frequency
                        );
                        if (
                            hasFrequencyComponent &&
                            (state.gift.frequency === Frequency.OneTime ||
                                !state.gift.frequency) &&
                            // @ts-ignore
                            state.amount >=
                                recurringAskPrompt.customData.minGiftAmount &&
                            // @ts-ignore
                            state.amount <=
                                recurringAskPrompt.customData.maxGiftAmount
                        ) {
                            return recurringAskPrompt;
                        }
                    }

                    return null;
                }
            };
        },

        hasErrors: (state: any) => {
            return state.errors.length > 0;
        },

        hasRequiredSubmitFields: (state: any) => {
            return (
                state.gift.amount &&
                state.gift.paymentMethodId &&
                state.gift.paymentMethodType
            );
        },

        hasOneSectionAndIsModalEmbed: (state: RenderStore) => {
            const sectionCount =
                    state.page.components.filter(
                        (x) => x.type === ComponentType.Section
                    ).length === 1,
                isPartial = state.page.pageType === PageType.Partial,
                isModal =
                    state.page.partialDisplayType == PartialDisplayType.Modal;

            return !state.builderMode && sectionCount && isPartial && isModal
                ? true
                : false;
        },

        canNotSubmit: (state: RenderStore) => {
            const hasErrors = state.errors.length > 0;
            const Loading = state.isLoading;
            const processing = state.isProcessing;

            if (state.usingPayPal) {
                // @ts-ignore
                return (
                    hasErrors || Loading || processing || !state.payPalApproved
                );
            }

            return hasErrors || Loading || processing;
        },

        stepHasErrors: (state) => {
            return (step: number) => {
                const stepErrors = state.errors.filter((x) => x.step === step);
                return stepErrors.length > 0;
            };
        },

        hasIntegration: (state: RenderStore) => {
            return (integrationType: IntegrationType) => {
                if (!state.page.integrations) return false;

                return state.page.integrations.some(
                    (x: PublicIntegration) =>
                        x.integrationType === integrationType
                );
            };
        },

        getIntegration: (state: RenderStore) => {
            return (integrationType: IntegrationType) => {
                if (!state.page.integrations) return null;

                return state.page.integrations.find(
                    (x: PublicIntegration) =>
                        x.integrationType === integrationType
                );
            };
        },

        hasSubscription: (state: RenderStore) => {
            return (subscriptionType: SubscriptionType) => {
                if (!state.organization?.subscriptions) return false;
                return state.organization.subscriptions.some(
                    (x) => x === subscriptionType
                );
            };
        },

        isTestMode: (state: RenderStore) => {
            if (state.page.isTestModeEnabled) return true;

            //check if the url contains isTesting=true query string
            const url = new URL(window.location.href);
            const isTesting = url.searchParams.get("isTesting");

            if (!isTesting) return false;

            return isTesting === "true";
        },

        tokenizationKey: (state: RenderStore) => {
            //@ts-ignore
            if (state.isTestMode) {
                return (
                    state.pageResponse.testTokenizationKey ??
                    state.page.testTokenizationKey
                );
            } else {
                return (
                    state.pageResponse.tokenizationKey ??
                    state.page.tokenizationKey
                );
            }
        },

        merchantAccount: (state: RenderStore) => {
            //@ts-ignore
            if (state.isTestMode) {
                if (state.page.merchantType === MerchantType.AuthorizeNet) {
                    return process.env.VUE_APP_TEST_AUTHNET_ACCOUNT;
                } else {
                    return process.env.VUE_APP_TEST_STRIPE_ACCOUNT;
                }
            } else {
                return state.page.merchantAccount;
            }
        },
    },
    actions: {
        async load(id: string, organizationId: number) {
            const publicApi = new publicApiService(this.apiBaseUrl);

            try {
                if (!this.previewMode) {
                    //remove instance from pageId
                    const pageId = id.split("_")[0];

                    const response = await publicApi.getPage(
                        pageId,
                        organizationId
                    );
                    const page = {
                        ...decodeJson(response.json),
                        merchantType: response.merchantType
                            ? MerchantType[response.merchantType]
                            : MerchantType.StripeUnified,
                        merchantAccount: response.merchantAccount,
                        tokenizationKey: response.tokenizationKey,
                        testTokenizationKey: response.testTokenizationKey,
                    };

                    this.page = page;
                    this.pageResponse = response;
                }

                this.setInitialCookieAcceptance();

                setPageCssVariables(this.page.pageDesign);

                this.loadGoogleFonts();

                // Inject custom CSS
                this.injectCssStyles(this.page.pageDesign.customCss);

                // Inject custom JS
                this.injectJavaScript(this.page.pageSettings.customJs);

                const isEmbed = this.page.pageType !== PageType.Full;
                if (!isEmbed) {
                    const style = document.createElement("style");
                    style.setAttribute("type", "text/css");
                    style.appendChild(
                        document.createTextNode(
                            "body { line-height: 1.2; margin: 0; }"
                        )
                    );
                    document.head.appendChild(style);
                }

                await this.setDefaults();
                this.runOnLoadActions();
            } catch (error) {
                console.error("Failed to load page data", error);
            }
        },

        loadOrganization(organizationId: number) {
            const publicApi = new publicApiService(this.apiBaseUrl);

            try {
                publicApi.getOrganization(organizationId).then((response) => {
                    this.organization = response;
                    this.setFavicon(response.faviconUrl);
                    this.injectJavaScript(decodeString(response.globalJS));
                });
            } catch (error) {
                console.error("Failed to load organization data", error);
            }
        },

        loadGoogleFonts() {
            //add pageDesign fontFamily if set
            if (this.page.pageDesign?.fontFamily) {
                this.addGoogleFont(this.page.pageDesign.fontFamily);
            }

            //flatten all components, and add google fonts if set
            for (const component of this.allComponents) {
                if (component.styles.fontFamily) {
                    this.addGoogleFont(component.styles.fontFamily);
                }
            }
        },

        addGoogleFont(fontFamily: string | null) {
            if (!fontFamily || fontFamily === "") return;

            const systemFonts = [
                "Arial",
                "Helvetica Neue",
                "Verdana",
                "TimesNewRoman",
                "Georgia",
            ];
            const isSystemFont = systemFonts.some((font) =>
                fontFamily.includes(font)
            );

            if (!isSystemFont) {
                const fontName = fontFamily
                    .split(",")[0]
                    .replace(/['"]/g, "")
                    .trim();
                const fontId = `${fontName}_google_font`;

                if (this.loadedFonts.has(fontId)) return;

                this.loadedFonts.add(fontId);

                const link = document.createElement("link");
                link.rel = "stylesheet";
                link.href = `https://fonts.googleapis.com/css?family=${fontName}:wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap`;
                link.id = fontId;
                document.head.appendChild(link);
            }
        },

        injectCssStyles(css: string | null) {
            if (!css) return;

            const style = document.createElement("style");
            style.setAttribute("type", "text/css");
            style.appendChild(document.createTextNode(css));
            document.head.appendChild(style);
        },

        injectJavaScript(js: string | null) {
            if (!js) return;

            const script = document.createElement("script");
            script.async = true;
            script.setAttribute("type", "text/javascript");
            script.appendChild(document.createTextNode(js));
            document.head.appendChild(script);
        },

        loadFontAwesome() {
            //check if font awesome css and js are loaded
            if (!document.getElementById("font-awesome-css")) {
                const fontAwesomeCss = document.createElement("link");
                fontAwesomeCss.id = "font-awesome-css";
                fontAwesomeCss.rel = "stylesheet";
                fontAwesomeCss.href =
                    "https://use.fontawesome.com/releases/v5.0.13/css/all.css";
                document.head.appendChild(fontAwesomeCss);
            }

            if (!document.getElementById("font-awesome-js")) {
                const fontAwesomeJs = document.createElement("script");
                fontAwesomeJs.id = "font-awesome-js";
                fontAwesomeJs.src = "https://kit.fontawesome.com/2acfc9c5aa.js";
                fontAwesomeJs.crossOrigin = "anonymous";
                fontAwesomeJs.async = true;
                document.head.appendChild(fontAwesomeJs);
            }
        },

        setInitialCookieAcceptance() {
            const cookieAcceptance = {
                accepted: false,
                acceptedAll: false,
                acceptedNecessary: false,
                acceptedStatistics: false,
                acceptedMarketing: false,
                timestamp: new Date().getTime(),
            } as CookieAcceptance;

            if (this.page.pageType !== PageType.Full) {
                // organizaiton must handle cookie compliance when embedding
                cookieAcceptance.accepted = true;
                cookieAcceptance.acceptedAll = true;
                cookieAcceptance.acceptedNecessary = true;
                cookieAcceptance.acceptedStatistics = true;
                cookieAcceptance.acceptedMarketing = true;
            }

            this.setCookieAcceptance(cookieAcceptance);
        },

        setCookieAcceptance(cookieAcceptance: CookieAcceptance | null) {
            this.cookieAcceptance = cookieAcceptance;
        },

        async setDefaults() {
            this.setGlobalObject();
            this.handleGlobalObjectDefaults();
            await this.handleURLParameters(); //url parameters override global object defaults
        },

        setGlobalObject() {
            try {
                // Initialize if not exists
                if (typeof window.RaiseDonorsForms === "undefined") {
                    window.RaiseDonorsForms = {
                        settings: {
                            firstName: null,
                            lastName: null,
                            email: null,
                            segmentOverrideCode: null,
                            projectOverrideCode: null,
                            frequency: null,
                            amount: null,
                            utm: {
                                utmCampaign: null,
                                utmSource: null,
                                utmMedium: null,
                                utmContent: null,
                                utmTerm: null,
                            },
                        },
                    };
                }

                // Watch for changes
                const observer = new MutationObserver((mutations) => {
                    if (
                        window.RaiseDonorsForms &&
                        window.RaiseDonorsForms !== this.globalObject
                    ) {
                        this.globalObject = window.RaiseDonorsForms;
                        this.handleGlobalObjectDefaults();
                    }
                });

                observer.observe(document, {
                    attributes: true,
                    childList: true,
                    subtree: true,
                });

                // Set initial value
                if (window.RaiseDonorsForms) {
                    this.globalObject = window.RaiseDonorsForms;
                    console.log(
                        "RaiseDonorsForms Object Initialized",
                        this.globalObject
                    );
                }
            } catch (error) {
                console.error("Error initializing RaiseDonorsForms:", error);
            }
        },

        handleGlobalObjectDefaults() {
            if (
                typeof this.globalObject === "undefined" ||
                !this.globalObject.settings
            )
                return;

            const settings = this.globalObject.settings;

            //set fields if they exist
            if (settings.firstName) this.donor.firstName = settings.firstName;
            if (settings.lastName) this.donor.lastName = settings.lastName;
            if (settings.email) this.donor.email = settings.email;
            if (settings.amount) this.setAmount(settings.amount.toString());
            if (settings.frequency)
                this.setFrequency(stringToFrequency(settings.frequency));

            this.setOverridesFromGlobalObject();
        },

        setOverridesFromGlobalObject() {
            if (
                typeof this.globalObject === "undefined" ||
                !this.globalObject.settings
            )
                return;

            const settings = this.globalObject.settings;

            //set fields if they exist
            if (settings.segmentOverrideCode)
                this.setSegmentOverrideCode(settings.segmentOverrideCode);
            if (settings.projectOverrideCode)
                this.setProjectOverrideCode(settings.projectOverrideCode);

            if (settings.utm) {
                //only set utm values if they are not already set
                if (!this.googleUtm.utmCampaign)
                    this.googleUtm.utmCampaign = settings.utm.utmCampaign;
                if (!this.googleUtm.utmSource)
                    this.googleUtm.utmSource = settings.utm.utmSource;
                if (!this.googleUtm.utmMedium)
                    this.googleUtm.utmMedium = settings.utm.utmMedium;
                if (!this.googleUtm.utmContent)
                    this.googleUtm.utmContent = settings.utm.utmContent;
                if (!this.googleUtm.utmTerm)
                    this.googleUtm.utmTerm = settings.utm.utmTerm;
            }
        },

        async handleURLParameters() {
            const urlParams = new URLSearchParams(window.location.search);

            //get segmentCode query parameter
            const segmentCode =
                urlParams.get("segmentCode") ??
                urlParams.get("segmentCodeOverride") ??
                urlParams.get("src") ??
                urlParams.get("referenceCode");
            if (segmentCode) {
                this.setSegmentOverrideCode(segmentCode);
            }

            //get projectCode query parameter
            const projectCode =
                urlParams.get("projectCode") ??
                urlParams.get("projectCodeOverride") ??
                urlParams.get("projectOverride") ??
                urlParams.get("fund-code");
            if (projectCode) {
                this.setProjectOverrideCode(projectCode);
            }

            //get frequency query parameter
            const urlFrequency = urlParams.get("frequency");
            if (urlFrequency) {
                const frequency = stringToFrequency(urlFrequency);
                this.setFrequency(frequency);
            }

            //get project pre-seelected query parameter
            const projectCodePreselect =
                urlParams.get("projectCodePreselect") ??
                urlParams.get("projectPreselect");
            if (projectCodePreselect) {
                const publicApi = new publicApiService(this.apiBaseUrl);
                await publicApi
                    .getProjectByCode(projectCodePreselect, this.organizationId)
                    .then((response) => {
                        this.page.projectPreselect = response;
                    });
            }

            //get amount query parameter
            const urlAmount =
                urlParams.get("amount") ??
                urlParams.get("amountPreselect") ??
                urlParams.get("amountOverride") ??
                urlParams.get("amt");
            let amount = 0;
            if (urlAmount) {
                amount = amountToNumber(urlAmount) ?? 0;
            }

            if (urlAmount && amount > 0) {
                this.setAmount(urlAmount ?? "0");
            }

            //get comment query parameter
            const comment =
                urlParams.get("comment") ?? urlParams.get("comments");
            if (comment) {
                this.gift.comments = comment;
            }
        },

        runOnLoadActions() {
            if (
                typeof this.globalObject === "undefined" ||
                !this.globalObject.settings
            )
                return;

            // run onload of user is using the RaiseDonorsForms object
            if (
                // @ts-ignore
                this.globalObject?.settings.onLoad &&
                // @ts-ignore
                typeof this.globalObject?.settings.onLoad == "function"
            ) {
                // @ts-ignore
                this.globalObject.settings.onLoad();
            }
        },

        runOnSuccessActions(response: DonateResponse) {
            if (
                typeof this.globalObject === "undefined" ||
                !this.globalObject.settings
            )
                return;

            // run onsuccess of user is using the RaiseDonorsForms object
            if (
                // @ts-ignore
                this.globalObject.settings.onSuccess &&
                // @ts-ignore
                typeof this.globalObject.settings.onSuccess == "function"
            ) {
                // @ts-ignore
                this.globalObject.settings.onSuccess(response);
            }
        },

        moveStep(actionType: ButtonActionType) {
            this.validateInputStep = this.step;

            switch (actionType) {
                case ButtonActionType.NextStep:
                    this.moveNextStep();
                    break;
                case ButtonActionType.PrevStep:
                    this.movePrevStep();
                    break;
                default:
                    return console.error("Unexpected Button Action Type");
            }
        },

        moveNextStep() {
            //don't allow moving forward if there are errors
            if (this.stepHasErrors(this.step)) return;

            //if there is an active prompt, do not move forward
            if (this.activePrompt) return;

            const activePrompt = this.getActivePrompt(false);
            if (activePrompt) {
                this.activePrompt = activePrompt;
                return;
            }

            if (this.step < this.maxSteps) this.step += 1;
        },

        movePrevStep() {
            if (this.step > 0) this.step -= 1;
        },

        moveToErrorStep() {
            const errorSteps = this.errors.map((x) => x.step as number);
            const firstStepWithError = Math.min(...errorSteps);
            this.step = firstStepWithError;
        },

        getAmount(projectId?: string | null): string {
            if (!projectId) return this.gift.amount ?? "0";

            return (
                this.gift.projectSplits?.find((x) => x.projectId === projectId)
                    ?.amount ?? ""
            );
        },

        setMaxSteps(maxSteps: number): void {
            this.maxSteps = maxSteps;
        },

        setEnvironment(environment: string): void {
            this.environment = environment;
        },

        setBuilderMode(builderMode: boolean): void {
            this.builderMode = builderMode;
        },

        setPreviewMode(previewMode: boolean): void {
            this.previewMode = previewMode;
        },

        setOrganizationId(organizationId: string): void {
            this.organizationId = parseInt(organizationId);
        },

        setFrequency(frequency: Frequency | null): void {
            this.gift.frequency = frequency;
        },

        setSegmentOverrideCode(segmentOverrideCode: string | null): void {
            this.page.segmentOverrideCode = segmentOverrideCode;
        },

        setProjectOverrideCode(projectOverrideCode: string | null): void {
            this.page.projectOverrideCode = projectOverrideCode;
        },

        setPromptAmount(amount: number, askFactor: number): void {
            //use the ask factor to set the amount
            this.gift.amount = amount.toString();

            //if there are projects present, apply the factor to each split
            if (this.gift.projectSplits.length > 0) {
                this.gift.projectSplits.forEach((projectSplit) => {
                    projectSplit.amount = (
                        amountToNumber(projectSplit.amount) * askFactor
                    ).toString();
                });

                //if the total amount of the project splits doesn't match the gift amount, adjust the first project split
                const projectSum = this.gift.projectSplits.reduce(
                    (sum, projectSplit) =>
                        sum + amountToNumber(projectSplit.amount),
                    0
                );

                if (projectSum !== amount) {
                    this.gift.projectSplits[0].amount = (
                        amount -
                        projectSum +
                        amountToNumber(this.gift.projectSplits[0].amount)
                    ).toString();
                }
            }
        },

        setAmount(
            amount: string,
            projectId?: string | null,
            projectName?: string | null,
            allowMultipleProjects?: boolean | null
        ): void {
            amount = amountToNumber(amount).toString();

            if (!this.gift.projectSplits) this.gift.projectSplits = [];

            if (!allowMultipleProjects && !projectId) {
                //if this gift array is not bound to a project there is a single gift array
                if (this.gift.projectSplits.length > 0) {
                    //and a user selected an amount after a project (project was set with 0)
                    const projectSplitAmount = (
                        amountToNumber(amount) / this.gift.projectSplits.length
                    ).toFixed(2);

                    this.gift.projectSplits.forEach((projectSplit) => {
                        projectSplit.amount = projectSplitAmount;
                    });
                }
            }

            //if this gift array is not bound to a project, just set the amount
            if (!projectId) {
                this.gift.amount = amount;
                return;
            }

            //if the project amount is the same, exit
            const existingProjectSplit = this.gift.projectSplits.find(
                (x) => x.projectId === projectId
            );
            if (existingProjectSplit && existingProjectSplit.amount === amount)
                return;

            //clear out any other project splits if we're not allowing multiple projects
            if (!allowMultipleProjects) {
                this.gift.projectSplits = this.gift.projectSplits.filter(
                    (x) => x.projectId == existingProjectSplit?.projectId
                );
            }

            if (existingProjectSplit) {
                if (!amount || existingProjectSplit.amount === amount) {
                    this.removeProject(projectId);
                    return;
                } else {
                    //update the amount
                    existingProjectSplit.amount = amount;
                }
            } else {
                //add a new project split
                const projectSplit: ProjectSplit = {
                    projectId: projectId,
                    projectName: projectName || "",
                    amount: amount,
                };

                this.gift.projectSplits.push(projectSplit);
            }

            //update the gift amount
            if (this.gift.projectSplits.length > 0) {
                let projectSum = 0;

                for (let i = 0; i < this.gift.projectSplits.length; i++) {
                    const projectSplit = this.gift.projectSplits[i];
                    const projectSplitAmount = amountToNumber(
                        projectSplit.amount
                    );

                    if (isNaN(projectSplitAmount)) continue;

                    projectSum += projectSplitAmount;
                }

                this.gift.amount = projectSum.toString();
            } else {
                this.gift.amount = amount;
            }
        },

        removeProject(projectId: string): void {
            const projectSplitIndex = this.gift.projectSplits.findIndex(
                (x) => x.projectId === projectId
            );
            if (projectSplitIndex > -1) {
                this.gift.projectSplits.splice(projectSplitIndex, 1);
            }

            //if there are no more project splits, set the gift amount to 0
            if (this.gift.projectSplits.length === 0) {
                this.gift.amount = formatCurrency("0");
            }
        },

        setPremium(premium: PremiumResponseModel | null): void {
            this.gift.premium = premium;
        },

        removePremium(): void {
            this.gift.premium = null;
        },

        async createPayPalOrder() {
            let paymentId = "" as string;

            const publicApi = new publicApiService(this.apiBaseUrl);
            await publicApi
                .createOrder(
                    this.page.payPalGatewayId ?? 0,
                    this.totalAmount,
                    this.gift.coverCosts,
                    this.isTestMode,
                    this.organizationId
                )
                .then((response) => {
                    this.paymentId = response.paymentIntentId;
                    this.gift.paymentMethodId = response.paymentIntentId;
                    this.gift.frequency = Frequency.OneTime; //force one time frequency for paypal

                    paymentId = response.paymentIntentId;
                })
                .catch((error) => {
                    this.handleFailure(error);
                });

            return paymentId;
        },

        async setPayPalApproval(
            approved: boolean,
            paymentSource: string | null
        ): Promise<void> {
            //this is async to satisfy the PayPal component
            this.payPalApproved = approved;
            this.usingPayPal = approved;
            this.paypalPaymentSource = paymentSource;

            if (!approved) {
                this.paymentId = null;
                this.gift.paymentMethodId = null;
            }
        },

        getRequest() {
            return {
                donor: {
                    title: this.donor.title,
                    firstName: this.donor.firstName,
                    middleName: this.donor.middleName,
                    lastName: this.donor.lastName,
                    suffix: this.donor.suffix,
                    email: this.donor.email,
                    phone: this.donor.phone,
                    phoneOptIn: this.donor.phoneOptIn,
                    isOrganization: this.donor.isOrganization,
                    organizationName: this.donor.organizationName,
                    crmKey: this.donor.crmKey,
                    billingAddress: this.donor.billingAddress
                        ? {
                              address1: this.donor.billingAddress?.address1,
                              address2: this.donor.billingAddress?.address2,
                              city: this.donor.billingAddress?.city,
                              state: this.donor.billingAddress?.state,
                              postal: this.donor.billingAddress?.postal?.toString(),
                              countryString:
                                  this.donor.billingAddress?.countryString,
                              addressType: AddressType.Billing,
                          }
                        : null,
                    shippingAddress: this.donor.shippingAddress
                        ? {
                              address1: this.donor.shippingAddress?.address1,
                              address2: this.donor.shippingAddress?.address2,
                              city: this.donor.shippingAddress?.city,
                              state: this.donor.shippingAddress?.state,
                              postal: this.donor.shippingAddress?.postal?.toString(),
                              countryString:
                                  this.donor.shippingAddress?.countryString,
                              addressType: AddressType.Shipping,
                          }
                        : null,
                },
                tribute: this.hasTribute
                    ? {
                          tributeFirstName: this.tribute.tributeFirstName,
                          tributeLastName: this.tribute.tributeLastName,
                          tributeAddress1:
                              this.tribute.tributeAddress?.address1,
                          tributeAddress2:
                              this.tribute.tributeAddress?.address2,
                          tributeCity: this.tribute.tributeAddress?.city,
                          tributeState: this.tribute.tributeAddress?.state,
                          tributeCountry:
                              this.tribute.tributeAddress?.countryString,
                          tributePostal:
                              this.tribute.tributeAddress?.postal?.toString(),
                          acknowledgeeFirstName:
                              this.tribute.acknowledgeeFirstName,
                          acknowledgeeLastName:
                              this.tribute.acknowledgeeLastName,
                          sendByEmail: this.tribute.sendByEmail,
                          acknowledgeeEmailAddress:
                              this.tribute.acknowledgeeEmailAddress,
                          sendByPostal: this.tribute.sendByPostal,
                          acknowledgeeAddress1:
                              this.tribute.acknowledgeeAddress?.address1,
                          acknowledgeeAddress2:
                              this.tribute.acknowledgeeAddress?.address2,
                          acknowledgeeCity:
                              this.tribute.acknowledgeeAddress?.city,
                          acknowledgeeState:
                              this.tribute.acknowledgeeAddress?.state,
                          acknowledgeeCountry:
                              this.tribute.acknowledgeeAddress?.countryString,
                          acknowledgeePostal:
                              this.tribute.acknowledgeeAddress?.postal?.toString(),
                          message: this.tribute.message,
                          isInHonorOf: this.tribute.isInHonorOf,
                          isInMemoryOf: this.tribute.isInMemoryOf,
                          NameOrOccasion: this.tribute.NameOrOccasion,
                      }
                    : null,
                publicId: this.pageResponse.publicId,
                pageRequestId: this.pageResponse.pageRequestId,
                nonce: this.pageResponse.nonce,
                isAnonymous: this.gift.isAnonymous,
                amount: this.amount,
                coverAdminFee: this.gift.coverAdminFee,
                adminFee: this.gift.adminFee,
                adminFeeProjectId: this.gift.adminFeeProject?.value,
                projects: this.gift.projectSplits,
                segment: this.page.defaultSegmentCode,
                segmentOverrideCode: this.page.segmentOverrideCode,
                projectOverrideCode: this.page.projectOverrideCode,
                isRecurring: this.usingPayPal
                    ? false
                    : this.gift.frequency != null &&
                      this.gift.frequency !== Frequency.OneTime,
                frequency: this.usingPayPal
                    ? Frequency.OneTime
                    : this.gift.frequency,
                startDate: this.gift.startDate,
                donorPaidCosts: this.gift.coverCosts,
                usePayPal: this.usingPayPal,
                submissionUrl: window.location.href,
                paymentMethodType: this.usingPayPal
                    ? PaymentMethodType.PayPal
                    : this.gift.paymentMethodType,
                creditCardType: this.gift.creditCardType,
                paymentMethodId: this.gift.paymentMethodId,
                giftAidRequested: false,
                premiumId: this.gift.premium?.id,
                doubleTheDonationCompanyId: null,
                isTestMode: this.isTestMode,
                doublethedonation_company_id: this.doubleTheDonationCompanyId,
                doublethedonation_entered_text:
                    this.doubleTheDonationEnteredText,
                visitorId: getCookie("vcrmvid"),
                comments: this.gift.comments,
                paymentId: this.paymentId,
                clientId: this.clientId,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                googleUtm: this.googleUtm,
                customFields: this.customFieldResponses,
                customCollections: this.customCollectionResponses.filter((x) =>
                    x.customFieldResponses.some((y) => y.value)
                ),
                paypalPaymentSource: this.paypalPaymentSource,
            } as DonateRequest;
        },

        async tokenizePaymentMethod() {
            let token = null as string | null;

            // don't tokenize in preview mode
            if (this.previewMode) {
                this.gift.paymentMethodId = "preview";
                return this.gift.paymentMethodId;
            }

            if (this.usingPayPal) {
                //it is already set by paypal
                return this.gift.paymentMethodId;
            }

            if (this.page.merchantType === MerchantType.StripeUnified)
                token = await this.stripeTokenization();
            else if (this.page.merchantType == MerchantType.AuthorizeNet)
                token = await this.authNetTokenization();

            return token;
        },

        async stripeTokenization() {
            let token = null as string | null;

            //if we are not submitting and are using the wallet, don't tokenize
            if (!this.isProcessing && this.usingWallet) {
                this.gift.creditCardType = "visa";
                token = "wallet";

                if (this.modelState.paymentMethodId) {
                    delete this.modelState.paymentMethodId;
                }

                if (this.modelState.paymentMethodType) {
                    delete this.modelState.paymentMethodType;
                }

                return token;
            }

            if (this.stripeElements) {
                const elements = this.stripeElements;
                await this.stripeElements.submit().then(async (result) => {
                    const billingDetails = this.buildStripeBillingDetails();

                    await this.stripe
                        ?.createPaymentMethod({
                            elements,
                            params: {
                                billing_details: billingDetails,
                            },
                        })
                        .then((result) => {
                            if (result.error) {
                                //show error
                                this.paymentElementError =
                                    result.error.message ?? "";
                                return null;
                            } else {
                                //set store billing address line 1 if not set using the stripe billing details
                                if (result.paymentMethod?.billing_details) {
                                    this.setBillingAddressFromStripe(
                                        result.paymentMethod?.billing_details
                                    );
                                }

                                if (result.paymentMethod?.card) {
                                    this.gift.creditCardType =
                                        result.paymentMethod.card.brand;
                                }

                                this.gift.paymentMethodId =
                                    result.paymentMethod?.id; //we need to set it so the input does not show error state
                                token = result.paymentMethod?.id;
                            }
                        });
                });

                return token;
            }

            return null;
        },

        buildStripeBillingDetails() {
            const billingDetails = {
                name: this.donor.firstName + " " + this.donor.lastName,
                email: this.donor.email ?? "",
                address: {
                    line1: this.donor.billingAddress?.address1 ?? "",
                    line2: this.donor.billingAddress?.address2 ?? "",
                    city: this.donor.billingAddress?.city ?? "",
                    state: this.donor.billingAddress?.state ?? "",
                    postal_code:
                        this.donor.billingAddress?.postal?.toString() ?? "",
                    country: this.donor.billingAddress?.countryString ?? "US", //assume US if no country is provided
                },
            } as PaymentMethodCreateParams.BillingDetails;

            return billingDetails;
        },

        setBillingAddressFromStripe(billingDetails: any) {
            //set billing address line 1
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                address1:
                    this.donor.billingAddress.address1 ??
                    billingDetails.address?.line1 ??
                    null,
            };

            //set billing address line 2
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                address2:
                    this.donor.billingAddress.address2 ??
                    billingDetails.address?.line2 ??
                    null,
            };

            //set billing city
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                city:
                    this.donor.billingAddress.city ??
                    billingDetails.address?.city ??
                    null,
            };

            //set billing state
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                state:
                    this.donor.billingAddress.state ??
                    billingDetails.address?.state ??
                    null,
            };

            //set billing postal code
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                postal:
                    this.donor.billingAddress.postal ??
                    billingDetails.address?.postal_code ??
                    null,
            };

            //set billing country
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                countryString:
                    this.donor.billingAddress.countryString ??
                    billingDetails.address?.country ??
                    null,
            };
        },

        async getCosts(coverCost: boolean) {
            if (!this.page.gatewayId || !this.organizationId) return;

            if (this.amount > 0) {
                const creditCardType = this.gift.creditCardType || "visa";
                const creditCardCountry =
                    this.donor.billingAddress?.countryString || "us";

                let paymentMethodId = this.gift.paymentMethodId;

                // if the payment method is wallet or preview, set it to null
                if (
                    paymentMethodId === "wallet" ||
                    paymentMethodId === "preview"
                ) {
                    paymentMethodId = null;
                }

                const costRequest: CostRequest = {
                    paymentMethodId: paymentMethodId,
                    gatewayId: this.page.gatewayId,
                    amount: this.totalAmount,
                    creditCardType: creditCardType,
                    creditCardCountry: creditCardCountry,
                    paymentMethodType:
                        this.gift.paymentMethodType || PaymentMethodType.Credit, // default to credit card
                    donorCoverCosts: coverCost,
                };

                this.isLoading = true;

                const publicApi = new publicApiService(this.apiBaseUrl);

                try {
                    const response = await publicApi.calculateCosts(
                        costRequest,
                        this.organizationId
                    );
                    this.gift.cost = amountToNumber(response.costs);
                } catch (error) {
                    console.error(error);
                } finally {
                    if (!this.page.payPalGatewayId) {
                        this.isLoading = false;
                    }
                }

                if (this.page.payPalGatewayId) {
                    const payPalCostRequest: CostRequest = {
                        paymentMethodId: paymentMethodId,
                        gatewayId: this.page.payPalGatewayId,
                        amount: this.totalAmount,
                        creditCardType: creditCardType,
                        creditCardCountry: creditCardCountry,
                        paymentMethodType: PaymentMethodType.PayPal,
                        donorCoverCosts: coverCost,
                    };

                    this.isLoading = true;

                    try {
                        const response = await publicApi.calculateCosts(
                            payPalCostRequest,
                            this.organizationId
                        );
                        this.gift.payPalCost = amountToNumber(response.costs);
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.isLoading = false;
                    }
                }
            }
        },

        async submitDonation() {
            if (this.canNotSubmit) return; //don't allow multiple submissions

            this.setOverridesFromGlobalObject();
            this.clearAllToasts;

            const activePrompt = this.getActivePrompt(true),
                confirmation = hasComponentByType(
                    this.allComponents,
                    ComponentType.Confirmation
                ),
                confirmationCustomData =
                    confirmation &&
                    this.allComponents.filter(
                        (x) => x.type === ComponentType.Confirmation
                    )[0].customData;

            if (activePrompt) {
                this.activePrompt = activePrompt;
                return;
            }

            this.isProcessing = true;

            //tokenize the payment method one last time to ensure its the latest
            this.gift.paymentMethodId = await this.tokenizePaymentMethod();

            // allow validation to complete before submitting
            this.validating = true;
            await nextTick();
            this.validating = false;

            // if there are errors, do not submit
            if (this.errors.length > 0) {
                this.isProcessing = false;
                return;
            }

            // if in preview mode, skip the api call
            if (this.previewMode) {
                this.transactionId = "123456";
                this.isProcessing = false;

                this.runOnSuccessActions({
                    success: true,
                    transactionId: "123456",
                } as DonateResponse);

                // if there's a redirect url, go there! if not, show confirmation step
                if (
                    confirmationCustomData.type == ConfirmationType.Redirect &&
                    confirmationCustomData.redirectUrl
                ) {
                    this.handleConfirmationRedirect(confirmationCustomData);
                } else {
                    this.success = true;
                }

                return;
            }

            const publicApi = new publicApiService(this.apiBaseUrl);
            publicApi
                .submitDonation(this.getRequest(), this.organizationId)
                .then((response) => {
                    if (response.success) {
                        if (window.dataLayer) {
                            const dataLayer = {
                                event: "purchase",
                                donationId: response.giftId,
                                amount: response.amount,
                                frequency: response.frequency,
                                segmentCode: response.segment,
                                projectCodes: response.projectSplit.map(
                                    (x) => x.projectCode
                                ),
                            };
                            window.dataLayer.push(dataLayer);
                        }

                        this.runOnSuccessActions(response);

                        // if there's a redirect url, go there!
                        if (
                            confirmationCustomData.type ==
                                ConfirmationType.Redirect &&
                            confirmationCustomData.redirectUrl
                        ) {
                            this.handleConfirmationRedirect(
                                confirmationCustomData
                            );

                            return;
                        }
                    }

                    this.handleSuccess(response);
                })
                .catch((error) => {
                    this.handleFailure(error);
                });
        },

        handleConfirmationRedirect(confirmationCustomData: any) {
            if (
                confirmationCustomData.addUrlParams &&
                confirmationCustomData.urlParams.length > 0
            ) {
                let url = new URL(confirmationCustomData.redirectUrl);
                url = this.appendConfirmationUrlParams(
                    url,
                    confirmationCustomData.urlParams
                );

                window.location.href = url.toString();
            } else {
                window.location.href = confirmationCustomData.redirectUrl;
            }
        },

        appendConfirmationUrlParams(url: URL, params: ConfirmationUrlParams[]) {
            if (!params || params.length === 0) return url;

            const urlObj = new URL(url);
            for (const param of params) {
                let value = "";
                switch (param) {
                    case ConfirmationUrlParams.DonorFirstName:
                        value = this.donor.firstName;
                        break;
                    case ConfirmationUrlParams.DonorLastName:
                        value = this.donor.lastName;
                        break;
                    case ConfirmationUrlParams.GiftAmount:
                        value = this.amount.toString();
                        break;
                    case ConfirmationUrlParams.Frequency:
                        if (!this.gift.frequency) break;
                        value = frequencyDisplayName(this.gift.frequency);
                        break;
                    case ConfirmationUrlParams.ProjectSplit:
                        value = this.gift.projectSplits
                            .map((x) => x.amount)
                            .join(",");
                        break;
                }

                if (value) {
                    urlObj.searchParams.append(param, value);
                }
            }

            return urlObj;
        },

        handleSuccess(response: DonateResponse) {
            if (response.requireAction) {
                // paymentComponent watches this value and will confirm card with stripe
                this.submissionRequiresAction = true;
                this.paymentClientSecret = response.paymentClientSecret;
                return;
            }

            this.transactionId = response.transactionId;
            this.success = response.success;

            if (
                this.success &&
                this.page.useTrackingPixel &&
                this.cookieAcceptance &&
                (this.cookieAcceptance.acceptedAll ||
                    this.cookieAcceptance.acceptedStatistics)
            ) {
                const trackingPixel = new trackingPixelService();
                trackingPixel.trackEvent(TrackingEventType.FormSubmit, {
                    formId: this.page.id?.toString() ?? "",
                    formName: this.page.name,
                });
            }

            // when use the default project, the gift.projectSplits is empty then we will get the value form server
            if (this.gift.projectSplits.length === 0) {
                this.gift.projectSplits = response.projectSplit;
            }

            this.isProcessing = false;
        },

        handleFailure(error) {
            console.log(error);

            // if the error is AxiosError, add the toast message
            if (error?.response.data[""]) {
                this.addToast(
                    `${error?.response.data[""][0]}`,
                    ToastType.Error
                );
            } else if (error?.response.data) {
                if (error.response.data.errors) {
                    this.setModelState(error.response.data.errors);
                } else {
                    this.setModelState(error.response.data);
                }
            } else if (error?.message) {
                this.addToast(`${error?.message}`, ToastType.Error);
            } else if (error) {
                this.addToast(error, ToastType.Error);
            } else {
                this.addToast(
                    "Uh oh! There was an unexpected error. Please try again soon.",
                    ToastType.Error
                );
            }

            this.isProcessing = false;
        },

        setModelState(modelState: any): void {
            this.modelState = modelState;
        },

        addToast(
            text: string,
            type?: ToastType | null,
            title?: string | null,
            duration?: number | null,
            hasIcon?: boolean | null,
            iconClass?: string | null,
            hasCloseButton?: boolean | null
        ) {
            const toast: Toast = {
                id:
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15),
                text: text,
                title: title ?? null,
                type: type ?? ToastType.Info,
                duration: duration ?? 5000,
                hasIcon: hasIcon ?? false,
                iconClass: iconClass ?? "",
                hasCloseButton: hasCloseButton ?? true,
            };

            this.toasts.push(toast);
        },

        clearToast(id: string) {
            const index = this.toasts.findIndex((toast) => toast.id === id);
            this.toasts.splice(index, 1);
        },

        clearAllToasts() {
            this.toasts = [];
        },

        resolvePrompt(promptId: string, decision: PromptDecision) {
            this.setPromptDecision(promptId, decision);

            if (this.maxSteps === this.step) {
                this.submitDonation();
            } else {
                this.moveStep(ButtonActionType.NextStep);
            }
        },

        setPromptDecision(promptId: string, decision: PromptDecision) {
            const prompt = this.getComponent(promptId) as IPromptComponent;
            if (prompt && prompt.promptDecision === null) {
                prompt.promptDecision = decision;
            }

            this.activePrompt = null;
            return;
        },

        setCustomValue(
            customFieldId: string | null | undefined,
            entityType: CustomFieldEntityType,
            dataType: CustomFieldDataType,
            value: string,
            customCollectionId: number | null | undefined,
            customCollectionInstance: string | null | undefined
        ) {
            if (!customFieldId) return;

            if (customCollectionId) {
                if (!customCollectionInstance) return;

                //find custom collection response by id and index (there may be more than 1 collection response)
                const customCollection = this.customCollectionResponses.find(
                    (x) =>
                        x.customCollectionId === customCollectionId &&
                        x.instanceId === customCollectionInstance
                );
                if (customCollection) {
                    //if the custom collection response exists, update the custom field value
                    const customField =
                        customCollection.customFieldResponses.find(
                            (x) => x.customFieldId === customFieldId
                        );
                    if (customField) {
                        customField.value = value;
                    } else {
                        customCollection.customFieldResponses.push({
                            customFieldId: customFieldId,
                            entityType: entityType,
                            dataType: dataType,
                            value: value,
                        });
                    }
                } else {
                    //if the custom collection response does not exist, create a new custom collection response
                    this.customCollectionResponses.push({
                        customCollectionId: customCollectionId,
                        instanceId: customCollectionInstance,
                        entityType: entityType,
                        customFieldResponses: [
                            {
                                customFieldId: customFieldId,
                                entityType: entityType,
                                dataType: dataType,
                                value: value,
                            },
                        ],
                    });
                }
            } else {
                if (!value) {
                    //remove the custom field if the value is empty
                    this.customFieldResponses =
                        this.customFieldResponses.filter(
                            (x) => x.customFieldId !== customFieldId
                        );
                    return;
                }

                const customField = this.customFieldResponses.find(
                    (x) => x.customFieldId === customFieldId
                );
                if (customField) {
                    customField.value = value;
                } else {
                    this.customFieldResponses.push({
                        customFieldId: customFieldId,
                        entityType: entityType,
                        dataType: dataType,
                        value: value,
                    });
                }
            }
        },

        addCustomCollectionInstance(
            customCollectionInstance: string,
            customCollectionId: number,
            entityType: CustomFieldEntityType
        ) {
            //check if the custom collection instance already exists
            if (
                this.customCollectionResponses.some(
                    (x) =>
                        x.customCollectionId === customCollectionId &&
                        x.instanceId === customCollectionInstance
                )
            )
                return;

            this.customCollectionResponses.push({
                customCollectionId: customCollectionId,
                instanceId: customCollectionInstance,
                entityType: entityType,
                customFieldResponses: [],
            });
        },

        removeCustomCollectionInstance(
            customCollectionInstance: string,
            customCollectionId: number
        ) {
            this.customCollectionResponses =
                this.customCollectionResponses.filter(
                    (x) =>
                        x.instanceId !== customCollectionInstance &&
                        x.customCollectionId !== customCollectionId
                );
        },

        getCustomCollectionFieldValue(
            customFieldId: string,
            customCollectionInstance: string
        ) {
            const customCollection = this.customCollectionResponses.find(
                (x) => x.instanceId === customCollectionInstance
            );
            if (!customCollection) return null;

            const customField = customCollection.customFieldResponses.find(
                (x) => x.customFieldId === customFieldId
            );
            if (!customField) return null;

            return customField.value;
        },

        setGoogleUTM(googleUtmRequest: GoogleUtmRequest) {
            this.googleUtm = googleUtmRequest;
        },

        authNetTokenization(): Promise<string | null> {
            return new Promise((resolve) => {
                this.paymentElementError = "";

                const authData = {
                    clientKey: this.tokenizationKey,
                    apiLoginID: this.merchantAccount,
                };
                const secureData: {
                    authData: any;
                    cardData?: any;
                    bankData?: any;
                } = {
                    authData,
                };

                if (this.gift.paymentMethodType == PaymentMethodType.Credit) {
                    if (
                        !this.anetPayment.cardNumber ||
                        !this.anetPayment.expDate ||
                        !this.anetPayment.cardCode
                    ) {
                        return resolve(null);
                    }

                    const [month, year] = this.anetPayment.expDate.split("/");
                    secureData.cardData = {
                        cardNumber: this.anetPayment.cardNumber,
                        month,
                        year,
                        cardCode: this.anetPayment.cardCode,
                    };
                } else if (
                    this.gift.paymentMethodType == PaymentMethodType.ACH
                ) {
                    if (
                        !this.anetPayment.accountNumber ||
                        !this.anetPayment.routingNumber ||
                        !this.anetPayment.accountType
                    ) {
                        return resolve(null);
                    }

                    secureData.bankData = {
                        accountNumber: this.anetPayment.accountNumber,
                        routingNumber: this.anetPayment.routingNumber,
                        nameOnAccount: sanitizeName(
                            `${this.donor.firstName} ${this.donor.lastName}`
                        ),
                        accountType: this.anetPayment.accountType,
                    };
                } else throw new Error("Not implemented");

                window.Accept.dispatchData(
                    secureData,
                    (response: {
                        opaqueData?: {
                            dataDescriptor: string;
                            dataValue: string;
                        };
                        messages: {
                            resultCode: string;
                            message: Array<{ code: string; text: string }>;
                        };
                    }) => {
                        if (response.messages.resultCode === "Error") {
                            const [message] = response.messages.message;
                            this.paymentElementError = message?.text ?? "";
                            return resolve(null);
                        }

                        this.gift.paymentMethodId =
                            response.opaqueData?.dataValue ?? null;
                        return resolve(response.opaqueData?.dataValue ?? null);
                    }
                );
            });
        },

        setFavicon(favicon: string | null) {
            if (!favicon) return; //if no favicon is provided, do nothing
            if (this.page.pageType !== PageType.Full) return; //only set favicon on hosted pages

            const link = document.querySelector(
                "link[rel='icon']"
            ) as HTMLLinkElement;
            if (!link) {
                const link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);

                link.href = favicon;
            } else {
                link.href = favicon;
            }
        },
    },
    share: {
        enable: false,
    },
});
