import axios, { AxiosInstance } from "axios";
import {
    AddressGetRequest,
    AddressSearchRequest,
    AddressSearchResponse,
    CampaignResponseModel,
    CreateOrderResponse,
    CustomCollectionResponseModel,
    CustomFieldResponseModel,
    DonateRequest,
    DonateResponse,
    OrderRequest,
    OrganizationModel,
    PageResponse,
    PageViewRequest,
    PremiumResponseModel,
    ProjectResponseModel,
    VisitorGivingResponse,
    VisitorRequest,
} from "../builder/interfaces";
import { getCookie } from "../builder/BuilderUtilities";
import { CostResponse, LookupResponse } from "../interfaces";
import CostRequest from "../interfaces/requests/CostRequest";

export class publicApiService {
    baseUrl: string;
    publicAxios: AxiosInstance;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;

        this.publicAxios = axios.create({
            withCredentials: false,
        });
    }

    createOrder = async (
        gatewayId: number,
        amount: number,
        donorPaidCosts: boolean,
        isTest: boolean,
        organizationId: number | null
    ) => {
        const orderRequest: OrderRequest = {
            gatewayId: gatewayId,
            amount: amount,
            donorPaidCosts: donorPaidCosts,
            isTest: isTest,
        };

        return this.post<CreateOrderResponse>(
            `${this.baseUrl}/donate/create-order/` + organizationId,
            orderRequest
        );
    };

    submitDonation = async (
        request: DonateRequest,
        organizationId: number | null
    ) =>
        this.post<DonateResponse>(
            `${this.baseUrl}/donate/` + organizationId,
            request
        );

    getOrganization = (organizationId: number | null) =>
        this.get<OrganizationModel>(
            `${this.baseUrl}/organization/${organizationId}`
        );

    getPage = (pageId: string, organizationId: number | null) =>
        this.get<PageResponse>(
            `${this.baseUrl}/page/${organizationId}/${pageId}`
        );

    getProject = (projectId: string, organizationId: number | null) =>
        this.get<ProjectResponseModel>(
            `${this.baseUrl}/project/${organizationId}/${projectId}`
        );

    getProjectByCode = (projectCode: string, organizationId: number | null) =>
        this.get<ProjectResponseModel>(
            `${this.baseUrl}/project/${organizationId}/code/${projectCode}`
        );

    searchProjects = (organizationId: number | null, filter: string | null) => {
        if (!organizationId) {
            return Promise.resolve([]);
        }

        const url = `${this.baseUrl}/project/${organizationId}/search`;

        if (filter) {
            return this.get<LookupResponse[]>(url, { filter });
        }

        return this.get<LookupResponse[]>(url);
    };

    getPinnedProjects = (organizationId: number | null) => {
        if (!organizationId) {
            return Promise.resolve([]);
        }

        return this.get<LookupResponse[]>(
            `${this.baseUrl}/project/${organizationId}/pinned`
        );
    };

    getPremium = (premiumId: string, organizationId: number | null) =>
        this.get<PremiumResponseModel>(
            `${this.baseUrl}/premium/${organizationId}/${premiumId}`
        );

    getCampaign = (campaignId: string, organizationId: number | null) =>
        this.get<CampaignResponseModel>(
            `${this.baseUrl}/campaign/${organizationId}/${campaignId}`
        );

    getCustomField = (customFieldId: number, organizationId: number | null) =>
        this.get<CustomFieldResponseModel>(
            `${this.baseUrl}/customfield/${organizationId}/${customFieldId}`
        );

    getCustomCollection = (
        customCollectionId: number,
        organizationId: number | null
    ) =>
        this.get<CustomCollectionResponseModel>(
            `${this.baseUrl}/customcollection/${organizationId}/${customCollectionId}`
        );

    getCountries = () =>
        this.get<LookupResponse[]>(`${this.baseUrl}/lookup/countries`);

    getCountry = (searchTerm: string | null) =>
        this.get<LookupResponse>(
            `${this.baseUrl}/lookup/country/${searchTerm}`
        );

    getStates = (countryCode: string | null) =>
        this.get<LookupResponse[]>(
            `${this.baseUrl}/lookup/states/${countryCode}`
        );

    searchAddress = async (
        organizationId: number | null,
        addressId: string | null,
        searchTerm: string | null,
        countryCode: string | null
    ) => {
        const request: AddressSearchRequest = {
            organizationId: organizationId,
            addressId: addressId,
            searchTerm: searchTerm,
            countryCode: countryCode,
        };

        const response = await this.get<AddressSearchResponse[]>(
            `${this.baseUrl}/lookup/address`,
            request
        );

        return response;
    };

    getAddress = async (
        addressId: string,
        organizationId: number | null,
        countryCode: string | null
    ) => {
        const request: AddressGetRequest = {
            organizationId: organizationId,
            countryCode: countryCode,
        };

        const response = await this.get<AddressSearchResponse>(
            `${this.baseUrl}/lookup/address/${addressId}`,
            request
        );

        return response;
    };

    calculateCosts = (
        costRequest: CostRequest,
        organizationId: number | null
    ) =>
        this.get<CostResponse>(
            `${this.baseUrl}/donate/cost/` + organizationId,
            costRequest
        );

    findVisitor = (organizationId: number | null) => {
        const visitorId = getCookie("vcrmvid") ?? "";
        const individualId = getCookie("vcrmiid") ?? "";

        const request: VisitorRequest = {
            visitorId: visitorId,
            individualId: individualId,
        };

        return this.get<VisitorGivingResponse>(
            `${this.baseUrl}/donate/visitor/${organizationId}`,
            request
        );
    };

    getFonts = () => {
        return this.get<LookupResponse[]>(`${this.baseUrl}/lookup/fonts`);
    };

    pageViewed = async (
        pageId: string,
        organizationId: number | null,
        clientId: string | null
    ) => {
        const request: PageViewRequest = {
            pageId: pageId,
            organizationId: organizationId,
            clientId: clientId,
        };
        await this.post<any>(`${this.baseUrl}/page/view`, request);
    };

    formStarted = async (
        pageId: string,
        organizationId: number | null,
        clientId: string | null
    ) => {
        const request: PageViewRequest = {
            pageId: pageId,
            organizationId: organizationId,
            clientId: clientId,
        };
        await this.post<any>(`${this.baseUrl}/page/form-started`, request);
    };

    private async get<T>(url: string, params?: any): Promise<T> {
        try {
            const response = await this.publicAxios.get<T>(url, { params });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private async post<T>(url: string, data?: any): Promise<T> {
        try {
            const response = await this.publicAxios.post<T>(url, data);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
