import {
    ComponentType,
    ContainerType,
    Direction,
    FieldStyle,
    PageType,
    PromptType,
    SubmitAction,
    TrustFAQItemType,
} from "@/builder/enums";
import { defineStore } from "pinia";
import { IntegrationType, MerchantType, Selection } from "../../enums";
import OrganizationDesignSettings from "../../interfaces/OrganizationDesignSettings";
import { IButtonComponent } from "../components/button/ButtonInterface";
import { IFooterComponent } from "../components/footer/IFooterInterface";
import { InputType } from "../components/textInput/TextInputInterface";
import { FormButtonType } from "../enums/FormButtonType";
import { ComponentErrors } from "../Constants";
import {
    ICustomComponent,
    IDonationBlockComponent,
    IFormComponent,
    IFormStepComponent,
    IHeaderComponent,
    IPage,
    IPageBuilderStore,
    IPremiumComponent,
    IPromptComponent,
    PublicIntegration,
} from "../interfaces/index";
import {
    deleteComponentById,
    flattenArray,
    generateUID,
    getComponentById,
    getComponentFriendlyName,
    getEmptyComponent,
    getIndexPath,
    getValidParentComponentTypes,
    hasComponentByType,
    pushErrorToComponents,
    removeErrorFromComponents,
    removeSortableDropZone,
    setComponentDefaults,
    setDesignDefaults,
    setNewComponentIds,
} from "../BuilderUtilities";
import { decodeJson, encodeJson } from "@/utilities/StringUtilities";

export const useBuilderStore = defineStore("builder", {
    state(): IPageBuilderStore {
        return {
            organizationId: null,
            page: {
                id: null,
                name: "",
                templateName: null,
                slug: null,
                publicId: null,
                components: [],
                prompts: [],
                pageType: PageType.Full,
                formType: null,
                partialDisplayType: null,
                gatewayId: null,
                selectedGateway: null,
                payPalGatewayId: null,
                merchantAccount: null,
                merchantType: MerchantType.Stripe,
                tokenizationKey: null,
                testTokenizationKey: null,
                gatewayCurrency: null,
                payPalSelectedGateway: null,
                payPalMerchantAccount: null,
                payPalTokenizationKey: null,
                payPalGatewayCurrency: null,
                isPublished: false,
                defaultSegment: null,
                defaultSegmentCode: null,
                defaultCampaignId: null,
                defaultCampaignName: null,
                segmentOverrideCode: null,
                projectOverrideCode: null,
                projectPreselect: null,
                defaultProject: null,
                defaultProjectCode: null,
                defaultProjectId: null,
                confirmationDonation: null,
                recurringEmail: null,
                acknowledgementEmail: null,
                integrations: [],
                useTrackingPixel: false,
                isTestModeEnabled: false,
                isTrustFAQCenterEnabled: true,
                trustFAQCenter: {
                    icon: "shield-heart",
                    trustFAQItems: [
                        {
                            id: generateUID("trust-faq-item_"),
                            type: TrustFAQItemType.HoverText,
                            title: "Is this donation secure?",
                            content:
                                "We partner with trusted, PCI-compliant payment processors who use industry-leading SSL encryption to protect your information throughout the entire donation process. By partnering with these providers, we ensure that your sensitive information never interacts with our servers.",
                        },
                        {
                            id: generateUID("trust-faq-item_"),
                            type: TrustFAQItemType.HoverText,
                            title: "Is my gift tax-deductible?",
                            content:
                                "Yes, we are a tax-exempt organization and as such, your gift to us is tax deductible according to the local rules where you reside. When you make a gift with us, you will receive a receipt of the transaction, which you should keep for your records.",
                        },
                        {
                            id: generateUID("trust-faq-item_"),
                            type: TrustFAQItemType.ExternalLink,
                            title: "Other ways to give",
                            content: "",
                        },
                    ],
                },
                customFields: [],
                pageDesign: {
                    websiteUrl: null,
                    logoUrl: null,
                    modalDisplay: null,
                    fieldStyle: FieldStyle.Default,
                    primaryColor: null,
                    secondaryColor: null,
                    backgroundColor: null,
                    headerColor: null,
                    headerTextColor: null,
                    bodyTextColor: null,
                    footerColor: null,
                    footerTextColor: null,
                    buttonColor: null,
                    buttonTextColor: null,
                    buttonHoverColor: null,
                    buttonHoverTextColor: null,
                    linkColor: null,
                    trustFaqTextColor: null,
                    fieldLabelColor: null,
                    fieldBorderColor: null,
                    borderRadius: null,
                    fontFamily: null,
                    customCss: null,
                    colorSwatches: null,
                },
                pageSettings: {
                    submitAction: SubmitAction.Message,
                    fundraisingGoal: "",
                    expirationDate: "",
                    motivationCode: "",
                    segmentCode: "",
                    mediaOutlet: "",
                    thankYouMessage: null,
                    showDifferentMessageForRecurring: false,
                    confirmationEmailSubject: "",
                    confirmationEmailContent: "",
                    customSocialSharingMessage: false,
                    openGraphTitle: null,
                    openGraphDescription: null,
                    openGraphImageUrl: null,
                    showDonorPortalLink: false,
                    showHelpAndInfoLink: false,
                    customJs: null,
                    hasMinAmount: false,
                    minAmount: null,
                    minAmountMessage: null,
                },
            },
            builder: {
                step: 0,
                maxStep: 3,
                showHeader: true,
                lastScrollTop: 0,
                selectedId: null,
                selectedType: null,
                selectedFormId: null,
                selectedFormStepId: null,
                selectedFormButtonType: null,
                selectedPromptType: null,
                expandForms: true,
                domain: null,
                activeContainerId: null,
                activeContainerType: ContainerType.Body,
                integrations: [],
                loadedFonts: new Set<string>(),
                historyIndex: 0,
                componentHistory: [],
                promptHistory: [],
            },

            isEditable: false,
            isLoading: false,

            modelState: {},
            isValid: true,

            originalState: null,
        };
    },
    getters: {
        /**
         * @name: Get Component
         * @desc: Finds a component by the specified identifier.
         **/
        allComponents(state: IPageBuilderStore) {
            //combine components and prompts
            return flattenArray([
                ...state.page.components,
                ...state.page.prompts,
            ]);
        },

        getComponent: (state: IPageBuilderStore) => {
            return (id: string) => {
                // @ts-ignore
                return getComponentById(state.allComponents, id);
            };
        },

        getFormButtonComponent: (state: IPageBuilderStore) => {
            return (formId: string, formButtonType: FormButtonType) => {
                const form = getComponentById(
                    state.page.components,
                    formId
                ) as IFormComponent;

                if (!form) return null;

                switch (formButtonType) {
                    case FormButtonType.Submit: {
                        return form.customData.buttons
                            .submit as IButtonComponent;
                    }
                    case FormButtonType.Modal: {
                        return form.customData.buttons
                            .modal as IButtonComponent;
                    }
                    default:
                        return null;
                }
            };
        },

        getFormStepButtonComponent: (state: any) => {
            return (formStepId: string, formButtonType: FormButtonType) => {
                const formStep = getComponentById(
                    state.page.components,
                    formStepId
                ) as IFormStepComponent;

                if (!formStep) return null;

                switch (formButtonType) {
                    case FormButtonType.Back: {
                        return formStep.customData.buttons
                            .prev as IButtonComponent;
                    }
                    case FormButtonType.Next: {
                        return formStep.customData.buttons
                            .next as IButtonComponent;
                    }
                    default:
                        return null;
                }
            };
        },

        hasPromptByType: (state: IPageBuilderStore) => {
            return (type: PromptType) => {
                return state.page.prompts.some((x) => x.promptType === type);
            };
        },

        selectedEditor(): string | null {
            if (!this.builder.selectedType) return null;

            return this.builder.selectedType.replace("-component", "-editor");
        },

        selectedTitle(): string {
            if (!this.builder.selectedType) return "Edit Component";

            return (
                this.builder.selectedType
                    ?.replace("-component", "")
                    ?.split("-")
                    ?.join(" ") ?? "Edit Component"
            );
        },

        showAddModal(): boolean {
            return this.builder.activeContainerId !== null;
        },

        pageTypeDisplay(): string {
            switch (this.page.pageType) {
                case PageType.Full: {
                    return "page";
                }
                case PageType.Partial: {
                    return "form";
                }
                case PageType.Component: {
                    return "element";
                }
                default:
                    return "page";
            }
        },

        pageUrl(): string | null {
            if (this.page.pageType !== PageType.Full) return null;

            return `${this.builder.domain}/donate/${this.page.slug}`;
        },

        oneTimeComponentTypes(): ComponentType[] {
            return flattenArray(this.page.components)
                .filter((x) => !x.controls.allowMultiple)
                .map((x) => x.type);
        },

        oneTimeDuplicates(): ICustomComponent[] {
            //get array of components that allow multiple is false and there is more than one
            const oneTimeComponents = flattenArray(this.page.components)
                .filter((x) => !x.controls.allowMultiple)
                .filter((x) => {
                    const components = flattenArray(
                        this.page.components
                    ).filter((y) => y.type === x.type);

                    return components.length > 1;
                });

            //we also need to find duplicates of any text input types (there can be multiple but only one of each type)
            const textInputTypes = [
                InputType.Address,
                InputType.Comments,
                InputType.Email,
                InputType.Name,
                InputType.PhoneNumber,
                InputType.BusinessName,
            ];

            textInputTypes.forEach((type) => {
                const components = flattenArray(this.page.components).filter(
                    (x) => x.type === ComponentType.TextInput
                );

                const textInputs = components.filter(
                    (x) => x.customData.type === type
                );

                if (textInputs.length > 1) {
                    oneTimeComponents.push(...textInputs);
                }
            });

            return oneTimeComponents;
        },

        customFieldDuplicates(): ICustomComponent[] {
            //there can be multiple custom field components but only one per custom field id
            const customFields = flattenArray(this.page.components).filter(
                (x) =>
                    x.type === ComponentType.CustomField &&
                    x.customData.selectedCustomField
            );

            const duplicate = customFields.filter(
                (x) =>
                    customFields.filter(
                        (y) =>
                            y.customData.selectedCustomField.value ===
                            x.customData.selectedCustomField.value
                    ).length > 1
            );

            return duplicate;
        },

        customCollectionDuplicates(): ICustomComponent[] {
            //there can be multiple custom collection components but only one per custom collection id
            const customCollections = flattenArray(this.page.components).filter(
                (x) => x.type === ComponentType.CustomCollection
            );

            const duplicate = customCollections.filter(
                (x) =>
                    customCollections.filter(
                        (y) =>
                            y.customData.selectedCustomCollection.value ===
                            x.customData.selectedCustomCollection.value
                    ).length > 1
            );

            return duplicate;
        },

        componentErrors(): string[] {
            const errors: string[] = [];

            //return list of errors for each component
            flattenArray(this.page.components).forEach((component) => {
                if (!component.errors) return;

                component.errors.forEach((error) => {
                    errors.push(error);
                });
            });

            return errors;
        },

        hasIntegration: (state: IPageBuilderStore) => {
            return (integrationType: IntegrationType) => {
                if (!state.builder.integrations) return false;

                return state.builder.integrations.some(
                    (x: PublicIntegration) =>
                        x.integrationType === integrationType
                );
            };
        },

        getIntegration: (state: IPageBuilderStore) => {
            return (integrationType: IntegrationType) => {
                if (!state.builder.integrations) return null;

                return state.builder.integrations.find(
                    (x: PublicIntegration) =>
                        x.integrationType === integrationType
                );
            };
        },

        canUndo: (state) => {
            return state.builder.historyIndex > 0;
        },

        canRedo: (state) => {
            return (
                state.builder.historyIndex <
                state.builder.componentHistory.length - 1
            );
        },
    },
    actions: {
        /*
         * Steps
         **/
        nextStep(): void {
            if (this.builder.step >= this.builder.maxStep) return;

            this.closeEditComponentPanel();

            this.builder.step += 1;
        },

        prevStep(): void {
            if (this.builder.step <= 0) return;

            this.closeEditComponentPanel();

            this.builder.step--;
        },

        moveToStep(step: number): void {
            //close editor panel
            this.closeEditComponentPanel();

            this.builder.step = step;
        },

        /**
         * Components
         **/

        openAddComponentPanel(
            containerId: string,
            containerType: ContainerType
        ): void {
            this.closeEditComponentPanel();
            this.builder.activeContainerId = containerId;
            this.builder.activeContainerType = containerType;
        },

        openEditComponentPanel(id: string, containerType: ContainerType): void {
            this.closeAddComponentPanel();
            this.builder.activeContainerType = containerType;
            this.setSelectedId(id);
        },

        openEditFormButtonPanel(
            formId: string,
            formButtonType: FormButtonType
        ): void {
            this.builder.selectedType = ComponentType.Button;

            this.builder.selectedFormId = formId;
            this.builder.selectedFormButtonType = formButtonType;

            this.builder.activeContainerType = ContainerType.Form;
        },

        openEditFormStepButtonPanel(
            formStepId: string,
            formButtonType: FormButtonType
        ): void {
            this.builder.selectedType = ComponentType.Button;

            this.builder.selectedFormStepId = formStepId;
            this.builder.selectedFormButtonType = formButtonType;

            this.builder.activeContainerType = ContainerType.Form_Step;
        },

        closeAddComponentPanel(): void {
            this.builder.activeContainerId = null;
        },

        closeEditComponentPanel(): void {
            this.builder.selectedType = null;
            this.builder.selectedId = null;
            this.builder.selectedFormId = null;
            this.builder.selectedFormButtonType = null;
            this.builder.selectedFormStepId = null;
        },

        setSelectedId(id: string): void {
            const component = this.getComponent(id);
            this.builder.selectedType = component.type;
            this.builder.selectedId = id;
        },

        dropInComponent(
            component: ICustomComponent,
            containerType: ContainerType,
            toContainerId: string,
            toIndex: number
        ): { success: boolean; message: string } {
            if (!component.id) {
                component.id = generateUID();
            }

            component.parentId = toContainerId;

            const toContainer = this.getComponent(toContainerId);

            //validate the component can be added to the container
            const validateAddResult = this.validateAddComponent(
                toContainer.type,
                component.type
            );
            if (!validateAddResult.isValid) {
                removeSortableDropZone();
                return {
                    success: false,
                    message: validateAddResult.message,
                };
            }

            switch (containerType) {
                case ContainerType.Prompt:
                case ContainerType.Form:
                case ContainerType.Form_Step:
                case ContainerType.Column:
                case ContainerType.Confirmation: {
                    toContainer.components.splice(toIndex, 0, component);
                    break;
                }
                default: {
                    removeSortableDropZone();
                    return {
                        success: false,
                        message: "Invalid container for component",
                    };
                }
            }

            pushErrorToComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );

            this.setSelectedId(component.id);
            this.closeAddComponentPanel();
            removeSortableDropZone();

            //track history
            this.trackHistory(this.page.components, this.page.prompts);

            return {
                success: true,
                message: "",
            };
        },

        addSection(id: string | null, containerType: ContainerType) {
            const emptySection = getEmptyComponent(ComponentType.Section);

            //if the id is null, we are adding the first section to the page
            if (!id) {
                //check if header is present, add below it otherwise add as first component
                const headerComponent = this.page.components.find(
                    (x) => x.type === ComponentType.Header
                );

                if (headerComponent) {
                    id = headerComponent.id;
                } else {
                    id = emptySection.id;
                }
            }

            this.builder.activeContainerId = id;
            this.builder.activeContainerType = containerType;

            this.addComponent(emptySection, containerType);
            this.setSelectedId(emptySection.id);

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        addHeader() {
            //make sure the page doesn't already have a header
            if (hasComponentByType(this.page.components, ComponentType.Header))
                return;

            //add the header to the page as the first component
            const emptyHeader = getEmptyComponent(
                ComponentType.Header,
                this.page
            );

            this.page.components.unshift(emptyHeader);

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        addFooter() {
            //make sure the page doesn't already have a footer
            if (hasComponentByType(this.page.components, ComponentType.Footer))
                return;

            //add the footer to the page as the last component
            const emptyFooter = getEmptyComponent(
                ComponentType.Footer,
                this.page
            );

            this.page.components.push(emptyFooter);

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        addFormStep(id: string) {
            //get the form component
            const form = this.getComponent(id);
            if (!form) return;

            const emptyFormStep = getEmptyComponent(ComponentType.FormStep);

            emptyFormStep.parentId = form.id;

            //add new form step to the form as first child component
            form.components.unshift(emptyFormStep);

            this.setSelectedId(emptyFormStep.id);

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        addComponent(
            component: ICustomComponent,
            containerType: ContainerType | null
        ): void {
            if (!this.builder.activeContainerId) return;
            if (!containerType) return;

            //if component id is not set, generate a new id
            if (!component.id) {
                component.id = generateUID();
            }

            component.parentId = this.builder.activeContainerId;

            switch (containerType) {
                case ContainerType.Body: {
                    let currentIndex = this.page.components
                        .map((x) => x.id)
                        .indexOf(this.builder.activeContainerId);

                    if (
                        currentIndex === 0 &&
                        this.page.components.find(
                            (x) => x.type === ComponentType.Header
                        )?.id === this.builder.activeContainerId
                    ) {
                        currentIndex++;
                    }
                    if (component.type === ComponentType.Footer) {
                        this.page.components.push(component);
                        break;
                    }
                    this.page.components.splice(currentIndex, 0, component);
                    break;
                }
                case ContainerType.Prompt:
                case ContainerType.Form:
                case ContainerType.Form_Step:
                case ContainerType.Column:
                case ContainerType.Confirmation: {
                    const container = this.getComponent(
                        this.builder.activeContainerId
                    );
                    container.components.unshift(component);
                    break;
                }
            }

            pushErrorToComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );

            this.setSelectedId(component.id);
            this.closeAddComponentPanel();

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        addPrompt(prompt: IPromptComponent): void {
            if (prompt.promptType === PromptType.RecurringAsk) {
                //validate there is not already a recurring ask prompt
                const hasRecurringAsk = this.hasPromptByType(
                    PromptType.RecurringAsk
                );
                if (hasRecurringAsk) {
                    return;
                }

                //validate a frequency component is present
                const hasFrequency = hasComponentByType(
                    this.allComponents,
                    ComponentType.Frequency
                );
                if (!hasFrequency) {
                    return;
                }
            }

            this.page.prompts.push(prompt);
            this.setSelectedId(prompt.id);
            this.closeAddComponentPanel();

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        dragComponent(
            fromContainerId: string,
            toContainerId: string,
            oldIndex: number,
            newIndex
        ): void {
            const isValid = this.validateMoveComponent(
                fromContainerId,
                toContainerId,
                oldIndex,
                newIndex
            );

            if (!isValid) {
                removeSortableDropZone();
                return;
            }

            //from and to containers are the same, just get the component and move it to the new index
            if (fromContainerId === toContainerId) {
                const container = this.getComponent(fromContainerId);
                const component = container.components.splice(oldIndex, 1)[0];
                container.components.splice(newIndex, 0, component);

                removeSortableDropZone();

                //track history
                this.trackHistory(this.page.components, this.page.prompts);

                return;
            }

            //get the from and to containers
            const fromContainer = this.getComponent(fromContainerId);
            const toContainer = this.getComponent(toContainerId);

            //get the component from the from container
            const component = fromContainer.components.splice(oldIndex, 1)[0];

            //set component parent id to the to container id
            component.parentId = toContainer.id;

            //add the component to the to container
            toContainer.components.splice(newIndex, 0, component);

            //revalidate one time components (in case things got weird)
            this.validateOneTimeComponents();

            removeSortableDropZone();

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        moveComponent(id: string, direction: Direction): void {
            const path = getIndexPath(this.page.components, id);
            const siblingComponents =
                path.length > 1
                    ? this.getComponent(path[path.length - 2].id)?.components
                    : this.page.components;

            const currentIndex = path[path.length - 1].index;
            let toIndex: number;

            //get header index
            const headerIndex = siblingComponents.findIndex(
                (x) => x.type === ComponentType.Header
            );

            //get footer index
            const footerIndex = siblingComponents.findIndex(
                (x) => x.type === ComponentType.Footer
            );

            switch (direction) {
                case Direction.Up:
                    if (currentIndex === 0) return;

                    toIndex = currentIndex - 1;

                    //if the toIndex equals the header index, return, we don't want to move above the header
                    if (toIndex === headerIndex) return;

                    break;
                case Direction.Down:
                    if (currentIndex === siblingComponents.length - 1) return;

                    toIndex = currentIndex + 1;

                    //if the toIndex equals the footer index, return, we don't want to move below the footer
                    if (toIndex === footerIndex) return;

                    break;
            }

            const item = siblingComponents[currentIndex];
            siblingComponents.splice(currentIndex, 1);
            siblingComponents.splice(toIndex, 0, item);

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        sortArray(array: any[], oldIndex: number, newIndex: number): any[] {
            const item = array.splice(oldIndex, 1)[0];
            array.splice(newIndex, 0, item);

            return array;
        },

        duplicateComponent(id: string): void {
            const component = this.getComponent(id);

            //duplicate the component
            const duplicate = JSON.parse(
                JSON.stringify(component)
            ) as ICustomComponent;

            //set new id for the duplicate and its children
            duplicate.id = generateUID();
            setNewComponentIds(duplicate.components);

            let path = [] as any[];
            if (duplicate.parentId) {
                const parentComponent = this.getComponent(duplicate.parentId);
                if (parentComponent.type === ComponentType.Prompt) {
                    path = getIndexPath(this.page.prompts, id);
                } else {
                    path = getIndexPath(this.page.components, id);
                }
            } else {
                path = getIndexPath(this.page.components, id);
            }

            const siblingComponents =
                path.length > 1
                    ? this.getComponent(path[path.length - 2].id)?.components
                    : this.page.components;

            const currentIndex = path[path.length - 1].index;
            siblingComponents.splice(currentIndex + 1, 0, duplicate);

            pushErrorToComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );

            //select the duplicate
            this.setSelectedId(duplicate.id);

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        deleteComponent(id: string): void {
            this.builder.selectedType = null;
            this.builder.selectedId = null;

            //clear errors from one time components
            removeErrorFromComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );

            const component = this.getComponent(id);

            let siblingId: string | undefined = undefined;
            if (this.builder.activeContainerType === ContainerType.Prompt) {
                siblingId = deleteComponentById(this.page.prompts, id);
            } else {
                siblingId = deleteComponentById(this.page.components, id);
            }

            //add errors for one time components duplicates
            pushErrorToComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );

            if (siblingId) {
                this.setSelectedId(siblingId);
            } else if (component.parentId) {
                this.setSelectedId(component.parentId);
            }

            //track history
            this.trackHistory(this.page.components, this.page.prompts);
        },

        clearComponents() {
            this.page.templateName = null;
            this.page.components = [];
        },

        handleDragOverEvent(sortableContainer: HTMLElement) {
            removeSortableDropZone();

            if (!sortableContainer) return;
            sortableContainer.classList.add("sortable-drop-zone");
        },

        validateAddComponent(
            parentComponentType: ComponentType,
            componentType: ComponentType
        ): { isValid: boolean; message: string } {
            const validComponentTypes =
                getValidParentComponentTypes(componentType);
            const isValid = validComponentTypes.includes(parentComponentType);

            if (!isValid) {
                const message = `Invalid location for ${getComponentFriendlyName(
                    componentType
                )}. Component can only be added to ${validComponentTypes
                    .map((x) => getComponentFriendlyName(x))
                    .join(" , ")}.`;

                return {
                    isValid,
                    message: message,
                };
            }

            return {
                isValid,
                message: "Yay! Valid location for component!",
            };
        },

        validateMoveComponent(
            fromContainerId: string,
            toContainerId: string,
            oldIndex: number,
            newIndex
        ): boolean {
            if (!fromContainerId || !toContainerId) return false;
            if (fromContainerId === toContainerId && oldIndex === newIndex)
                return false;

            //get the from and to containers
            const fromContainer = this.getComponent(fromContainerId);
            const toContainer = this.getComponent(toContainerId);

            //get the component from the from container
            const component = fromContainer.components[oldIndex];

            //check if the component can be moved to the to container
            const validComponentTypes = getValidParentComponentTypes(
                component.type
            );
            return validComponentTypes.includes(toContainer.type);
        },

        validateOneTimeComponents() {
            //clear errors from one time components
            removeErrorFromComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );

            //clear errors from text inputs
            const textInputs = flattenArray(this.page.components).filter(
                (x) => x.type === ComponentType.TextInput
            );

            removeErrorFromComponents(
                textInputs,
                ComponentErrors.DuplicateComponent
            );

            //add errors for one time components duplicates
            pushErrorToComponents(
                this.oneTimeDuplicates,
                ComponentErrors.DuplicateComponent
            );
        },

        validateOneTimeCustomFields() {
            //clear errors from custom field components
            removeErrorFromComponents(
                this.allComponents.filter(
                    (x) => x.type === ComponentType.CustomField
                ),
                ComponentErrors.DuplicateComponent
            );

            //add errors for duplicate custom field components
            pushErrorToComponents(
                this.customFieldDuplicates,
                ComponentErrors.DuplicateComponent
            );
        },

        validateOneTimeCustomCollections() {
            //clear errors from custom collection components
            removeErrorFromComponents(
                this.allComponents.filter(
                    (x) => x.type === ComponentType.CustomCollection
                ),
                ComponentErrors.DuplicateComponent
            );

            //add errors for duplicate custom collection components
            pushErrorToComponents(
                this.customCollectionDuplicates,
                ComponentErrors.DuplicateComponent
            );
        },

        /**
         *  Integrations
         **/

        addIntegrationIfNotExists(integrationType: IntegrationType) {
            if (this.hasIntegration(integrationType)) {
                if (
                    this.page.integrations.filter(
                        (x) => x.integrationType === integrationType
                    ).length > 0
                )
                    return;

                const integration = this.getIntegration(integrationType);

                if (integration) this.page.integrations.push(integration);
            }
        },

        /**
         *  Builder State
         **/

        setModelState(modelState: any): void {
            this.isValid = Object.keys(modelState).length === 0;
            this.modelState = modelState;
        },

        expandForms() {
            this.builder.expandForms = !this.builder.expandForms;
        },

        setTemplate(templateName: string, templateData: string) {
            this.page.templateName = templateName;

            const page = JSON.parse(templateData) as IPage;

            this.page.partialDisplayType = page.partialDisplayType;
            this.page.prompts = page.prompts;
            this.page.components = page.components;
            this.page.pageDesign.modalDisplay = page.pageDesign.modalDisplay;
            this.page.pageDesign.customCss = page.pageDesign.customCss;
            this.page.pageSettings = {
                ...this.page.pageSettings,
                submitAction: page.pageSettings.submitAction,
                thankYouMessage: page.pageSettings.thankYouMessage,
                showDifferentMessageForRecurring:
                    page.pageSettings.showDifferentMessageForRecurring,
                confirmationEmailSubject:
                    page.pageSettings.confirmationEmailSubject,
                confirmationEmailContent:
                    page.pageSettings.confirmationEmailContent,
                customSocialSharingMessage:
                    page.pageSettings.customSocialSharingMessage,
                showDonorPortalLink: page.pageSettings.showDonorPortalLink,
                showHelpAndInfoLink: page.pageSettings.showHelpAndInfoLink,
                customJs: page.pageSettings.customJs,
            };

            //if the template has a header, we need to replace its logoUrl
            const header = this.allComponents.find(
                (x) => x.type === ComponentType.Header
            ) as IHeaderComponent;

            if (header) {
                header.customData.logoUrl = this.page.pageDesign.logoUrl;
            }

            //if the template has a footer, we need to replace its logo and websiteUrl
            const footer = this.allComponents.find(
                (x) => x.type === ComponentType.Footer
            ) as IFooterComponent;

            if (footer) {
                footer.customData.logoUrl = this.page.pageDesign.logoUrl;
                footer.customData.websiteUrl = this.page.pageDesign.websiteUrl;
            }

            //if the template has premium component, we need to rmeove the selected premiums
            const premiumComponents = this.allComponents.find(
                (x) => x.type === ComponentType.Premium
            ) as IPremiumComponent;

            if (premiumComponents) {
                premiumComponents.customData.premiumArray = [];
            }

            //if the template has a donation block, we need to remove the proejcts
            const donationBlock = this.allComponents.find(
                (x) => x.type === ComponentType.DonationBlock
            ) as IDonationBlockComponent;

            if (donationBlock) {
                if (
                    !donationBlock.customData.projectSettings.projectSelection
                ) {
                    donationBlock.customData.projectSettings.projectSelection =
                        Selection.Include;
                }

                donationBlock.customData.projectSettings.availableProjects = [];
                donationBlock.customData.projectSettings.excludedProjects = [];
            }
        },

        /**
         * @name: Export State
         * @desc: take the state of the builder, serializes it to a JSON string
         *      and then compresses using btoa (base-64) compression.
         **/
        get() {
            return encodeJson(this.$state.page);
        },

        /**
         * @name: Import State
         * @desc: takes the temp state and applies it to reload from a specific point.
         **/
        load(page: any, isExistingPage: boolean) {
            this.page = {
                ...this.page,
                ...decodeJson(page.json),
            };

            this.builder.step = isExistingPage ? 2 : 0;
            this.builder.integrations = page.integrations;

            this.page.id = page.id;
            this.page.name = page.name;
            this.page.slug = page.slug;
            this.page.isPublished = page.isPublished;
            this.page.publicId = page.publicId;
            this.page.gatewayId = page.gatewayId;
            this.page.merchantType = page.merchantType;
            this.page.merchantAccount = page.merchantAccount;
            this.page.tokenizationKey = page.tokenizationKey;
            this.page.testTokenizationKey = page.testTokenizationKey;
            this.page.payPalGatewayId = page.payPalGatewayId;
            this.page.payPalMerchantAccount = page.payPalMerchantAccount;
            this.page.payPalTokenizationKey = page.payPalTokenizationKey;
            this.page.defaultSegmentCode = page.defaultSegmentCode;
            this.page.defaultProjectId = page.defaultProjectId;
            this.page.defaultProjectCode = page.defaultProjectCode;
            this.page.defaultCampaignId = page.defaultCampaignId;

            setDesignDefaults(
                this.page,
                page.designSettings as OrganizationDesignSettings
            );
            setComponentDefaults(this.page.components, this.page);

            if (page.confirmationEmailId) {
                this.page.confirmationDonation = {
                    id: page.confirmationEmailId,
                    name: page.confirmationEmailName,
                };
            }

            if (page.recurringEmailId) {
                this.page.recurringEmail = {
                    id: page.recurringEmailId,
                    name: page.recurringEmailName,
                };
            }

            if (page.acknowledgementEmailId) {
                this.page.acknowledgementEmail = {
                    id: page.acknowledgementEmailId,
                    name: page.acknowledgementEmailName,
                };
            }

            if (page.defaultProjectId) {
                this.page.defaultProject = {
                    value: page.defaultProjectId,
                    text: page.defaultProjectName,
                    metadata: null,
                };
            }

            this.originalState = this.get();

            this.loadGoogleFonts();
        },

        reset() {
            this.$reset();
        },

        handleSuccessfulSave(response: IPage) {
            this.page.id = response.id;
            this.page.isPublished = response.isPublished;
            this.page.publicId = response.publicId;
        },

        loadGoogleFonts() {
            //add pageDesign fontFamily if set
            if (this.page.pageDesign?.fontFamily) {
                this.addGoogleFont(this.page.pageDesign.fontFamily);
            }

            //flatten all components, and add google fonts if set
            for (const component of this.allComponents) {
                if (component.styles.fontFamily) {
                    this.addGoogleFont(component.styles.fontFamily);
                }
            }
        },

        addGoogleFont(fontFamily: string | null) {
            if (!fontFamily || fontFamily === "") return;

            const systemFonts = [
                "Arial",
                "Helvetica Neue",
                "Verdana",
                "TimesNewRoman",
                "Georgia",
            ];
            const isSystemFont = systemFonts.some((font) =>
                fontFamily.includes(font)
            );

            if (!isSystemFont) {
                const fontName = fontFamily
                    .split(",")[0]
                    .replace(/['"]/g, "")
                    .trim();
                const fontId = `${fontName}_google_font`;

                if (this.builder.loadedFonts.has(fontId)) return;

                this.builder.loadedFonts.add(fontId);

                const link = document.createElement("link");
                link.rel = "stylesheet";
                link.href = `https://fonts.googleapis.com/css?family=${fontName}:wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap`;
                link.id = fontId;
                document.head.appendChild(link);
            }
        },

        encodeState(state: any): string {
            return encodeJson(state);
        },

        decodeState(encodedState: string): any {
            return decodeJson(encodedState);
        },

        clearHistory() {
            this.builder.componentHistory = [];
            this.builder.promptHistory = [];
            this.builder.historyIndex = 0;
        },

        trackHistory(
            currentComponentState: ICustomComponent[],
            currentPromptState: IPromptComponent[]
        ) {
            const maxHistoryLength = 20;

            // Serialize and encode the current state using TextEncoder
            const serializedComponentState = this.encodeState(
                currentComponentState
            );
            const serializedPromptState = this.encodeState(currentPromptState);

            // Remove history items after the current index
            this.builder.componentHistory = this.builder.componentHistory.slice(
                0,
                this.builder.historyIndex + 1
            );
            this.builder.promptHistory = this.builder.promptHistory.slice(
                0,
                this.builder.historyIndex + 1
            );

            // Add the current state to the history
            this.builder.componentHistory.push(serializedComponentState);
            this.builder.promptHistory.push(serializedPromptState);

            // Ensure history length does not exceed maxHistoryLength
            if (this.builder.componentHistory.length > maxHistoryLength) {
                this.builder.componentHistory.shift();
            }
            if (this.builder.promptHistory.length > maxHistoryLength) {
                this.builder.promptHistory.shift();
            }

            // Update the history index
            this.builder.historyIndex =
                this.builder.componentHistory.length - 1;
        },

        undo() {
            if (this.builder.historyIndex <= 0) return;

            // Decrement the history index
            this.builder.historyIndex--;

            // Restore the previous state
            this.page.components = this.decodeState(
                this.builder.componentHistory[this.builder.historyIndex]
            );
            this.page.prompts = this.decodeState(
                this.builder.promptHistory[this.builder.historyIndex]
            );

            this.closeEditComponentPanel();
        },

        redo() {
            if (
                this.builder.historyIndex >=
                this.builder.componentHistory.length - 1
            )
                return;

            // Increment the history index
            this.builder.historyIndex++;

            // Restore the next state
            this.page.components = this.decodeState(
                this.builder.componentHistory[this.builder.historyIndex]
            );
            this.page.prompts = this.decodeState(
                this.builder.promptHistory[this.builder.historyIndex]
            );

            this.closeEditComponentPanel();
        },
    },

    share: {
        enable: false,
    },
});
